import { FC } from 'react';
import SettingsTabs from '../../../../components/Settings/Tabs';
import { useCurrentRole, useWorkspace } from '../../../../utils/helpers/common';
import { Navigate } from 'react-router-dom';

const WorkspaceSettings: FC = () => {
  const workspace = useWorkspace();

  const { isAdmin, isOrgAdmin } = useCurrentRole();

  if (!isAdmin && !isOrgAdmin) {
    return <Navigate to='/dashboard' replace={true} />;
  }

  const tabs = [
    {
      type: `Workspace Settings - ${workspace.name}`,
      tabs: [
        {
          name: 'api-keys',
          label: 'API Keys',
        },
        {
          name: 'integrations',
          label: 'Integrations',
        },
        {
          name: 'members',
          label: 'Workspace Members',
        },
        {
          name: 'product',
          label: 'Product Configurations',
          tabs: [
            {
              name: 'workflow',
              label: 'Assignment Workflow',
            },
            {
              name: 'case',
              label: 'Case Preferences',
            },
            {
              name: 'config',
              label: 'Monitoring Config',
            },
            {
              name: 'smart-contract-watchlist',
              label: 'Smart Contract Watchlist',
            },
            {
              name: 'custom-lists',
              label: 'Custom Lists',
            },
          ],
        },
        {
          name: 'risk',
          label: 'Risk Management',
          tabs: [
            {
              name: 'policies',
              label: 'Risk Policies',
            },
            {
              name: 'rules',
              label: 'Rules',
            },
            {
              name: 'custom-tags',
              label: 'Custom Tags (Rules)',
            },
          ],
        },
      ],
    },
  ];

  return <SettingsTabs tabs={tabs} type='workspace' />;
};

export default WorkspaceSettings;
