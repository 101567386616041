import { FC, useEffect } from 'react';
import * as d3 from 'd3';
import * as d3dag from 'd3-dag';
import { IStageList } from '../../../../api/dtos/stages';
import { IStageOptions } from './AssignmentWorkflow';

interface AssignmentWorkflowMatrixProps {
  stageData: IStageList[];
  isLoading: boolean;
  currentStage: IStageOptions;
}

function arrowTransform({ points }: { points: readonly (readonly [number, number])[] }): string {
  const padding = 25;
  const [[x1, y1], [x2, y2]] = points.slice(-2);
  const angle = (Math.atan2(x2 - x1, y2 - y1) * 180) / Math.PI + 90;
  return `translate(${y2 + padding}, ${x2 + 5}) rotate(${angle})`;
}

interface ColorMapInterface {
  background: string;
  textColor: string;
}

const colorMap: ColorMapInterface[] = [
  {
    background: '#FED7AA',
    textColor: '#1F2937',
  },
  {
    background: '#FB923C',
    textColor: '#1F2937',
  },
  {
    background: '#C2410C',
    textColor: '#FFFFFF',
  },
  {
    background: '#F97316',
    textColor: '#FFFFFF',
  },
  {
    background: '#FDBA74',
    textColor: '#1F2937',
  },
  {
    background: '#FFEDD5',
    textColor: '#1F2937',
  },
  {
    background: '#FFF7ED',
    textColor: '#1F2937',
  },
  {
    background: '#F3F4F6',
    textColor: '#1F2937',
  },
];

const AssignmentWorkflowMatrix: FC<AssignmentWorkflowMatrixProps> = ({
  stageData,
  isLoading,
  currentStage,
}) => {
  const generateCombinations = (stageData: IStageList[]) => {
    const combinations: [string, string][] = currentStage?.label ? [['-NA-', currentStage?.label]] : [];
    stageData?.forEach((obj) => {
      const currentName = obj.name;

      obj.next_stages?.forEach((nextObj) => {
        combinations.push([currentName, nextObj.name]);
      });
    });
    return combinations;
  };
  function wrap(text, width) {
    text.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      const textY = text.attr('y');
      const textRows = Math.ceil(text.text().length / 20);
      const y = textRows > 1 ? textY - textRows * 3 : textY;

      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1;
      const dy = parseFloat(text.attr('dy') || 0);
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word);
        }
      }
    });
  }

  const graphData = generateCombinations(stageData);
  const drawChart = () => {
    const builder = d3dag.graphConnect();
    const graph = builder(graphData);

    const nodeRadius = 30;
    const nodeSize = [nodeRadius / 2, nodeRadius * 3] as const;
    const shape = d3dag.tweakShape([nodeRadius, nodeRadius * 3], d3dag.shapeEllipse);
    const line = d3.line().curve(d3.curveNatural);
    const layout = d3dag
      .zherebko()
      .nodeSize(nodeSize)
      .gap([nodeRadius, nodeRadius * 2])
      .tweaks([shape]);

    layout(graph);

    // global
    const svg = d3.select('#svg').style('height', 125);
    const trans = svg.transition().duration(750);

    svg.select('#nodes').selectAll('g').remove();
    svg.select('#links').selectAll('path').remove();
    svg.select('#arrows').selectAll('path').remove();

    // nodes
    svg
      .select('#nodes')
      .selectAll('g')
      .data(graph.nodes())
      .join((enter) =>
        enter
          .append('g')
          .attr('transform', ({ x, y }) => `translate(${y}, ${x})`)
          .attr('opacity', 0)
          .call((enter) => {
            const padding = 5;
            enter
              .append('text')
              .text((d) => d.data)
              .attr('font-size', 11)
              .attr('font-weight', '500')
              .attr('y', padding + 3)
              .attr('alignment-baseline', 'middle')
              .attr('fill', (d) => {
                if (d.data === '-NA-') {
                  return colorMap[7].textColor;
                }
                return colorMap[stageData?.findIndex((stage) => stage.name === d.data)]?.textColor;
              })
              // ...
              .call(wrap, nodeRadius * 4)
              .each(function () {
                const textWidth = this.getBBox().width;
                const textHeight = this.getBBox().height;
                const rectWidth = textWidth + 2 * padding > 65 ? textWidth + 2 * padding : 65;
                d3.select(this).attr('dx', textWidth > 30 ? 0 : 13);

                d3.select(this.parentNode)
                  .insert('rect', 'text')
                  .attr('x', -padding)
                  .attr('y', -textHeight / 2)
                  .attr('width', rectWidth)
                  .attr('height', textHeight + 2 * padding)

                  .attr('fill', (d) => {
                    if (d.data === '-NA-') {
                      return colorMap[7].background;
                    }
                    return (
                      colorMap[stageData?.findIndex((stage) => stage.name === d.data)]?.background || '#fff'
                    );
                  })
                  .attr('rx', 12)
                  .attr('ry', 12);
              });
            enter.transition(trans).attr('opacity', 1);
          })
      );

    // link paths
    svg
      .select('#links')
      .selectAll('path')
      .data(graph.links())
      .join((enter) =>
        enter
          .append('path')
          .attr('d', ({ points }) => {
            const padding = 25;
            const paddingX = 5;
            return line(points.map(([x, y]) => [y + padding, x + paddingX]));
          })
          .attr('fill', 'none')
          .attr('stroke-width', 3)
          .attr('stroke', `#B3B3B3`)
          .attr('opacity', 0)
          .call((enter) => enter.transition(trans).attr('opacity', 1))
      );

    // Arrows
    const arrowSize = 80;
    const arrowLen = Math.sqrt((4 * arrowSize) / Math.sqrt(3));
    const arrow = d3.symbol().type(d3.symbolTriangle).size(arrowSize);
    svg
      .select('#arrows')
      .selectAll('path')
      .data(graph.links())
      .join((enter) =>
        enter
          .append('path')
          .attr('d', arrow)
          .attr('fill', '#B3B3B3')
          .attr('transform', arrowTransform)
          .attr('opacity', 0)
          .attr('stroke', 'white')
          .attr('stroke-width', 2)
          .attr('stroke-dasharray', `${arrowLen},${arrowLen}`)
          .call((enter) => enter.transition(trans).attr('opacity', 1))
      );

    const nodesArray = Array.from(graph.nodes());

    // Calculate maximum x-coordinate
    const maxY = Math.max(...nodesArray.map((node) => node.y));
    const maxX = Math.max(...nodesArray.map((node) => node.x));
    const svgWidth = maxY + nodeRadius * 3;
    const svgHeight = maxX + nodeRadius * 3;

    svg.style('width', svgWidth);
    svg.style('height', svgHeight);
  };
  useEffect(() => {
    if (!isLoading && stageData?.length !== 0) {
      drawChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, currentStage?.label]);

  return (
    <div className='mt-3'>
      <h4 className='mb-3 text-base'>Stage Transitions Matrix</h4>
      {!isLoading && stageData.length !== 0 && (
        <div className='flex w-fit items-center justify-start rounded border border-gray-300 bg-gray-50 py-8'>
          <svg id='svg'>
            <g transform='translate(2, 6)'>
              <defs id='defs' />
              <g id='links' />
              <g id='nodes' />
              <g id='arrows' />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default AssignmentWorkflowMatrix;
