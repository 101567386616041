import { FC, useRef, useState } from 'react';
import { IAlertsHistory } from '../../../../../api/dtos/alert';
import { useAlertGetAlert, useAlertGetAlertCycles, useAlertGetAlertItems } from '../../../../../api/alert';
// import { useParams } from 'react-router-dom';
import { ArrowUpRight, ArrowsInSimple, Chats, Circle, ClockCounterClockwise } from '@phosphor-icons/react';
import { dateToString, formatDateTime, formatDurationHelper } from '../../../../../utils/helpers/date';
import { useAuth } from '../../../../../modules/auth';
import { BasicBadge, Button, RiskBadge } from '../../../../../ui';
import AlertInfoItem from '../AlertInfo/AlertInfoItem';
import Rule from '../../../../Risks/Rule';
import Pagination from '../../../../../containers/Pagination';
import InvestigationTree from '../../../../AddressTransaction/InvestigationTree/InvestigationTree';
import { IAddressResponse } from '../../../../../api/dtos/address';
import { ITransactionResponse } from '../../../../../api/dtos/transaction';
import { Card } from '../../../../ui/components/Card';
import { commentApi, useCommentGetComments } from '../../../../../api/comment';
import Comment from '../../../AuditTrail/Comment';
import Quill from '../../../../ui/components/Quill';
import { IPostCommentsUser } from '../../../../../api/dtos/comment';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import CounterpartySummary from '../../../../AddressTransaction/CounterpartySummary';
import AddressTransactions from '../../../../Address/AddressTransactions';
import uniq from 'lodash/uniq';
import Popover from '../../../../../ui/components/Popover/Popover';
import CompassLoader from '../../../../ui/components/Loader/CompassLoader';
import classNames from 'classnames';
import EmptyAlertDetailSection from './EmptyAlertDetailSection';
import TransactionSummary from '../../../../Transaction/Overview/TransactionSummary/TransactionSummary';
import StageAndAssignedWorkSpace from '../../../../../components/common/StageAndAssignedWorkspace';

interface IAlertDetailsProps {
  alert: number;
  type: 'address' | 'transaction';
  id: string | number;
  onClose: () => void;
  address?: IAddressResponse;
  transaction?: ITransactionResponse;
}

const AlertDetails: FC<IAlertDetailsProps> = ({ alert, type, id, onClose, address, transaction }) => {
  // const { addressId } = useParams();
  // TODO: use react-router

  const { state } = useAuth();

  const [section, setSection] = useState<'triggers' | 'history' | 'comments'>(null);
  const [historyItem, setHistoryItem] = useState<IAlertsHistory>(null);
  const [historyItemType, setHistoryItemType] = useState<'comment' | 'alert_item'>(null);

  const {
    data: alertData,
    isLoading,
    isError,
  } = useAlertGetAlert({
    id,
    alertId: alert,
    type,
  });

  const currentEntities = uniq(
    alertData?.data?.latest_context.map((context) => context.tag_type_verbose)
  ).filter(Boolean);
  const currentEntitySubTypesLong = uniq(
    alertData?.data?.latest_context.map((context) => context.tag_subtype_verbose)
  ).filter(Boolean);
  const currentEntitySubTypes = currentEntitySubTypesLong.map((subType) => subType.split(': ')[1]);
  // Only one entity per alert as per BE (txn api also supports only 1)
  const currentEntityName = uniq(
    alertData?.data?.latest_context.map((context) => context.tag_name_verbose)
  ).filter(Boolean)[0];

  const currentTimeRef = useRef(new Date().toISOString());

  const alertItems = useAlertGetAlertItems(
    {
      id,
      alertId: alertData?.data.id,
      type,
      start_time: alertData?.data && alertData.data.opened_at,
      end_time: currentTimeRef.current,
    },
    {
      enabled: section === 'triggers' && !isLoading,
    }
  );

  const alertHistory = useAlertGetAlertCycles(
    {
      type,
      id,
      alertId: alertData?.data.id,
    },
    {
      enabled: section === 'history' && !isLoading,
    }
  );

  const alertHistoryItem = useAlertGetAlertItems(
    {
      id,
      alertId: alertData?.data.id,
      type,
      start_time: historyItem && historyItem.opened_at,
      end_time: historyItem && historyItem.resolved_at,
    },
    {
      enabled: section === 'history' && !!historyItem && historyItemType === 'alert_item' && !isLoading,
    }
  );

  const alertHistoryComments = useCommentGetComments(
    {
      id,
      type,
      filters: {
        alert: alertData?.data.id,
        start_time: historyItem && historyItem.opened_at,
        end_time: historyItem && historyItem.resolved_at,
      },
    },
    {
      enabled: section === 'history' && !!historyItem && historyItemType === 'comment' && !isLoading,
    }
  );

  const alertComments = useCommentGetComments(
    {
      id: alertData?.data.id,
      type: 'alert',
      filters: {
        // alert: alertData?.data.id,
        start_time: alertData?.data && alertData.data.opened_at,
        // end_time: currentTimeRef.current,
      },
    },
    {
      enabled: section === 'comments' && !isLoading,
    }
  );
  const sectionData = {
    triggers: 'More instances since last resolution',
    history: 'Alert(s) history',
    comments: 'Comments in current Alert',
  };
  const alertTags = alertData?.data
    ? [
        // ...(type === 'customer' ? [startCase(alert.entity_type) + ' Risk'] : []),
        alertData?.data.rule_register.policy_category_verbose,
        ...alertData.data.rule_register.risk_types.map((riskType) => riskType.risk_type_verbose),
        // Hide created at and reviewed by for now
        // formatDateTime(alert.created_at, state.userProfile.timezone),
        // ...(alert.status === 0 && alert.reviewed_by ? ['Reviewed by ' + alert.reviewed_by] : []),
        ...(alertData?.data.status === 1 && alertData?.data.resolved_by
          ? ['Resolved by ' + alertData?.data.resolved_by]
          : []),
        ...(alertData?.data.status === 2 && alertData?.data.escalated_by
          ? ['Escalated by ' + alertData?.data.escalated_by]
          : []),
      ]
    : [];

  const queryClient = useQueryClient();

  const { mutate } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      toast.success('Added Comment successfully');
      queryClient.invalidateQueries(['commentApi.getComments']);
    },
  });

  const onSubmit = (value: string, files: FileList, users: IPostCommentsUser[]) => {
    mutate({
      id: alertData?.data?.id,
      type: 'alert',
      body: value,
      files,
      context: {
        users,
        alerts: [
          { rule_name: alertData?.data.alert_body, id: alertData?.data.id, start_position: 0, length: 0 },
        ],
      },
    });
  };

  const isDirect = alertData?.data?.rule_register.rule_conditions.some((r) =>
    [1, 2, 3, 22, 23, 24].includes(r.rule_condition_type)
  );
  const isIndirect = alertData?.data?.rule_register.rule_conditions.some((r) =>
    [18, 19, 20].includes(r.rule_condition_type)
  );

  const onAlertHistoryButtonClick = (type: 'alert_item' | 'comment', item: IAlertsHistory) => {
    if (historyItemType === type) {
      setHistoryItem(null);
      setHistoryItemType(null);
    } else {
      setHistoryItem(item);
      setHistoryItemType(type);
    }
  };

  const handleSectionClick = (clickedSection: 'triggers' | 'history' | 'comments') => () => {
    if (section === clickedSection) {
      setSection(null);
      return;
    }
    setSection(clickedSection);
  };

  const getPreviousTriggersText = () => {
    const alertItemsCount = alertData.data.alert_items_count === 0 ? 0 : alertData.data.alert_items_count - 1;
    return alertItemsCount ? (
      <>
        Previous Triggers ({alertItemsCount})
        <ArrowUpRight weight='fill' className='ml-2' size={16} />
      </>
    ) : (
      'No Previous Triggers'
    );
  };

  if (isLoading) {
    return <CompassLoader />;
  }

  return (
    <div>
      <div className='sticky top-0 z-30 -mx-4 -mt-2 bg-white px-4 py-2'>
        <div className='mb-2 pt-1'>
          <span className='cursor-pointer text-xs font-medium uppercase text-blue-600' onClick={onClose}>
            &lt;- Back to {type} Overview
          </span>
        </div>
        {!isError ? (
          <>
            <div className='mb-1 flex justify-between'>
              <Popover
                referenceContent={
                  <div className='flex text-lg text-gray-800'>
                    <div className='flex'>
                      <RiskBadge
                        risk={alertData?.data.level}
                        className='mr-3'
                        grayScale={[2, 3].includes(alertData?.data?.workflow_stage?.category)}
                      />
                    </div>
                    {alertData?.data.alert_body}
                  </div>
                }
                popoverContent={'Rule Name'}
                className='ml-2 text-sm text-gray-800'
                placement='right'
                isBasic
              />
            </div>
            <StageAndAssignedWorkSpace
              selectedAlertID={alert?.toString()}
              type={type}
              id={id}
              assigned_to_name={alertData?.data?.assigned_to}
              stage_name={alertData?.data?.workflow_stage?.name}
              stage_id={alertData?.data?.workflow_stage?.id}
              next_stage_options={alertData?.data?.workflow_stage?.next_stages.map((r) => ({
                label: r.name,
                value: r.name,
                id: r.id,
              }))}
            />
            <div className='mt-2 flex flex-wrap text-xs text-gray-500'>
              {alertTags.map((tag, i) => (
                <div key={i} className='flex'>
                  {tag}
                  {<Circle size={5} weight='fill' className='mx-3 my-auto' />}
                </div>
              ))}
              <div className='text-xs text-gray-500'>Alert ID: {alertData?.data.id}</div>
            </div>
          </>
        ) : (
          <div className='flex h-64 items-center justify-center text-gray-700'>
            Error loading alert details
          </div>
        )}
      </div>
      {!isError && (
        <div>
          <div className='rounded-md border'>
            <AlertInfoItem
              id={id}
              alertItem={{
                identifier: '',
                body: alertData.data.latest_item_body,
                created_at: alertData.data.updated_at,
                updated_by: alertData.data.updated_by,
                updated_at: alertData.data.updated_at,
              }}
              alert={alertData.data}
              type={type}
            />
          </div>

          <div className='my-2 grid grid-cols-3 gap-2'>
            <Button
              variant='tertiary'
              className='justify-center disabled:text-gray-400'
              onClick={handleSectionClick('triggers')}
              disabled={alertData.data.alert_items_count < 2}>
              {getPreviousTriggersText()}
            </Button>
            <Button
              variant='tertiary'
              className='justify-center'
              iconEnd={<ClockCounterClockwise weight='fill' className='ml-2 text-blue-600' size={16} />}
              onClick={handleSectionClick('history')}>
              Alert(s) history
            </Button>
            <Button
              variant='tertiary'
              className='justify-center'
              iconEnd={<Chats className='ml-2 text-black' size={16} />}
              onClick={handleSectionClick('comments')}>
              Comments in current Alert
            </Button>
          </div>
          {section && (
            <div className='my-2 rounded-md border'>
              <div className='flex justify-between rounded-t-md border-b bg-gray-100 p-2 text-xs shadow-md'>
                <div className='my-auto font-semibold uppercase text-gray-500'>{sectionData[section]}</div>
                <Button variant='secondary' onClick={() => setSection(null)}>
                  Collapse
                  <ArrowsInSimple size={16} className='ml-1' weight='fill' />
                </Button>
              </div>
              {section === 'triggers' && (
                <div className='bg-gray-100 px-2 pt-2'>
                  <Pagination query={alertItems} className='max-h-[300px]'>
                    {(item, i) => (
                      <div
                        // Hide first item as it is shown in last alert cycle
                        key={item.identifier}
                        className={classNames('mb-2 rounded-md border bg-white', {
                          hidden: i === 0,
                        })}>
                        <AlertInfoItem
                          id={id}
                          alert={alertData.data}
                          alertItem={item}
                          key={item.identifier}
                          type={type}
                        />
                      </div>
                    )}
                  </Pagination>
                </div>
              )}
              {section === 'history' && (
                <div className='bg-gray-100'>
                  <Pagination query={alertHistory} className='max-h-[300px]'>
                    {(item) => (
                      <div className='border-b' key={item.id}>
                        <div className='grid grid-cols-5 p-4'>
                          <div className='text-sm'>
                            <div className='mb-3 font-medium text-gray-500'>Opened on</div>
                            <div className='font-semibold'>
                              {formatDateTime(item.opened_at, state.userProfile.timezone)}
                            </div>
                          </div>
                          <div className='text-sm'>
                            <div className='mb-3 font-medium text-gray-500'>Resolved on</div>
                            <div className='font-semibold'>
                              {formatDateTime(item.resolved_at, state.userProfile.timezone)}
                            </div>
                          </div>
                          <div className='text-sm'>
                            <div className='mb-3 font-medium text-gray-500'>Resolved by</div>
                            <div className='font-semibold'>{item.resolved_by}</div>
                          </div>
                          <div className='text-sm'>
                            <div className='mb-3 font-medium text-gray-500'>Turn Around Time:</div>
                            <div className='font-semibold'>{formatDurationHelper(item.duration)}</div>
                          </div>
                          <div className='my-auto flex justify-end'>
                            <Button
                              variant='tertiary'
                              className='mr-2 p-2 px-3'
                              disabled={!item.alert_items_count}
                              onClick={() => onAlertHistoryButtonClick('alert_item', item)}>
                              <ClockCounterClockwise weight='fill' size={16} className='mr-2' />
                              {item.alert_items_count}
                            </Button>
                            <Button
                              variant='tertiary'
                              className='p-2 px-3'
                              onClick={() => onAlertHistoryButtonClick('comment', item)}>
                              <Chats size={16} />
                            </Button>
                          </div>
                        </div>
                        {historyItem?.id === item.id && historyItemType === 'alert_item' && (
                          <Pagination query={alertHistoryItem} className='max-h-[300px]'>
                            {(item) => (
                              <div key={item.identifier} className='mx-4 my-2.5 rounded-md border bg-white'>
                                <AlertInfoItem id={id} alert={alertData.data} alertItem={item} type={type} />
                              </div>
                            )}
                          </Pagination>
                        )}
                        {historyItem?.id === item.id && historyItemType === 'comment' && (
                          <Pagination
                            query={alertHistoryComments}
                            className='m-4 max-h-[300px] rounded-lg border bg-white'>
                            {(item) => (
                              <div className='' key={item.id}>
                                <Comment comment={item} />
                              </div>
                            )}
                          </Pagination>
                        )}
                      </div>
                    )}
                  </Pagination>
                </div>
              )}
              {section === 'comments' && (
                <div className='grid grid-cols-3 bg-white'>
                  <Pagination query={alertComments} className='col-span-2 max-h-[300px]'>
                    {(item) => (
                      <div className='' key={item.id}>
                        <Comment comment={item} />
                      </div>
                    )}
                  </Pagination>
                  <div>
                    <Quill type={type} id={id} onSubmit={onSubmit} />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='rounded-md border bg-gray-50'>
            <div className='border-b p-4'>
              <div className='mb-2 text-sm font-medium uppercase tracking-wide text-gray-400'>Policy</div>
              <div className='mb-1 text-sm text-gray-800'>
                Policy Name: {alertData.data.rule_register.policy_name}
              </div>
              {alertData.data.rule_register.policy_description && (
                <div className='mb-1 text-sm text-gray-800'>
                  Policy Description: {alertData.data.rule_register.policy_description}
                </div>
              )}
            </div>
            {!alertData.data.rule_register.rule_conditions.some(
              (condition) => condition.rule_condition_type === 25 || condition.rule_condition_type === 26
            ) && (
              <div className='px-4 py-2'>
                <div className='mb-2 text-sm font-medium uppercase tracking-wide text-gray-400'>
                  Rule - {alertData.data.rule_register.rule_name}
                </div>
                <div className='rounded-md border p-4'>
                  {alertData.data.rule_register.rule_conditions.map((r, i) => (
                    <div key={i} className='my-2 text-xs leading-6 text-gray-500'>
                      <Rule ruleType={r.rule_condition_type} type={type} {...r} />
                      {/* Add AND */}
                      {i !== alertData.data.rule_register.rule_conditions.length - 1 && (
                        <div className='text-gray-500'>AND</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='flex gap-2 px-4 py-2 text-xs text-gray-700'>
              {alertData.data.rule_register.custom_tag_name && (
                <div className='flex items-center gap-1'>
                  <p>Custom Tag: </p>
                  <BasicBadge className='bg-purple-100 text-purple-900'>
                    {alertData.data.rule_register.custom_tag_name}
                  </BasicBadge>
                </div>
              )}
              {alertData.data.rule_register.custom_tag_name && alertData.data.rule_register?.action && (
                <Circle size={5} weight='fill' className='mx-1 my-auto' />
              )}
              {alertData.data.rule_register?.action && (
                <div className='flex items-center gap-1'>
                  <p>Action Keyword: </p>
                  <BasicBadge className='bg-gray-200 text-gray-900'>
                    {alertData.data.rule_register?.action}
                  </BasicBadge>
                </div>
              )}
            </div>
          </div>
          <div className='-mx-4 bg-gray-50 px-4'>
            <div className='mt-2.5 bg-gray-50 p-3 text-xs font-semibold uppercase text-gray-500'>
              Relevant activity since last resolution
            </div>
            {isDirect || isIndirect ? (
              <Card title='INVESTIGATION MODE' containerClassName='border border-gray-200'>
                <InvestigationTree
                  originType={type}
                  identifier={id.toString()}
                  address={address}
                  transaction={transaction}
                  defaultEntities={currentEntities}
                  defaultEntitySubTypes={currentEntitySubTypes}
                  defaultEntityName={currentEntityName}
                />
              </Card>
            ) : (
              <EmptyAlertDetailSection title='Investigation mode' />
            )}
            <div className='mt-2.5'>
              {isDirect || isIndirect ? (
                <CounterpartySummary
                  type={type}
                  address={address}
                  transaction={transaction}
                  defaultEntities={currentEntities}
                  defaultEntitySubTypes={currentEntitySubTypes}
                  defaultEntityName={currentEntityName}
                />
              ) : (
                <EmptyAlertDetailSection title='Counterparty summary' />
              )}
            </div>

            {type === 'address' ? (
              <div className='mt-2.5'>
                {isDirect ? (
                  <AddressTransactions
                    address={address}
                    defaultEntities={currentEntities}
                    defaultEntitySubTypes={currentEntitySubTypes}
                    defaultEntityName={currentEntityName}
                    startDate={
                      alertData.data.resolved_at ? dateToString(new Date(alertData.data.resolved_at)) : null
                    }
                    endDate={alertData.data.resolved_at ? dateToString(new Date()) : null}
                  />
                ) : (
                  <EmptyAlertDetailSection title='Transactions' />
                )}
              </div>
            ) : (
              <div className='mt-2.5'>
                <TransactionSummary
                  transaction={transaction}
                  defaultEntities={currentEntities}
                  defaultEntitySubTypes={currentEntitySubTypes}
                  defaultEntityName={currentEntityName}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertDetails;
