import { FC } from 'react';
import BasicBadge from '../../../../ui/components/Badges/BasicBadge/BasicBadge';
import { getCurrencyById, getCurrencyImageById } from '../../../../utils/helpers/currency';

export interface CurrencyBadgeProps {
  currency: number;
  label?: React.ReactNode;
  isLabelVerbose?: boolean;
  transparent?: boolean;
  className?: string;
}

const CurrencyBadge: FC<CurrencyBadgeProps> = (props) => {
  if (props.currency == null) return null;
  const label =
    props.label ?? getCurrencyById(props.currency)[props.isLabelVerbose ? 'label' : 'currency_short'];
  return (
    <BasicBadge
      className={`px-1 py-0.5 ${props.transparent ? 'bg-transparent' : 'bg-gray-100'} ${props.className}`}>
      <img src={getCurrencyImageById(props.currency)} width={16} />
      <div className='mx-1'> {label} </div>
    </BasicBadge>
  );
};

export default CurrencyBadge;
