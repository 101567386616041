import { FC, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { clone } from 'lodash';
import CheckboxController from '../../../ui/components/Form/CheckboxController';
import classNames from 'classnames';
import RadioButtonsController from '../../../ui/components/Form/RadioButtonsController';
import { Button, RadioButton } from '../../../ui';
import { IIntegrationProps } from '../../../views/dashboard/settings/Integrations/IntegrationDetails';

type SummaryNotificationTypes = 'monthly' | 'weekly';
const initialData = {
  summaryReport: false,
  summaryReportType: null as SummaryNotificationTypes,
  fileUploads: false,
  riskDetectionNotifications: false,
  alertItemNotifications: false,
  riskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
};

const SlackForm: FC<IIntegrationProps> = ({ data, onSubmit }) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: initialData,
  });

  const summaryReport = useWatch({ control, name: 'summaryReport' });
  const riskDetectionNotifications = useWatch({ control, name: 'riskDetectionNotifications' });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data == null) {
      return;
    }

    const summaryReportType = data.config.monthly_report
      ? 'monthly'
      : data.config.weekly_report
        ? 'weekly'
        : null;

    const riskLevels = clone(initialData.riskLevels);

    Object.keys(riskLevels).forEach((key) => {
      riskLevels[key] = data.config.risk?.includes(key);
    });

    setValue('summaryReport', !!summaryReportType);
    setValue('summaryReportType', summaryReportType);
    setValue('fileUploads', data.config.file_upload_status);
    setValue('riskDetectionNotifications', data.config.alert_item || !!data.config?.risk?.length);
    setValue('riskLevels', riskLevels);
    setValue('alertItemNotifications', data.config.alert_item);
  }, [setValue, data]);

  const onSave = async (form: typeof initialData) => {
    const config = {
      weekly_report: form.summaryReport && form.summaryReportType === 'weekly',
      monthly_report: form.summaryReport && form.summaryReportType === 'monthly',
      file_upload_status: !!form.fileUploads,
      risk: form.riskDetectionNotifications
        ? Object.keys(form.riskLevels).filter((key) => form.riskLevels[key])
        : [],
      alert_item: !!(form.alertItemNotifications && form.riskDetectionNotifications),
    };
    setLoading(true);
    await onSubmit({
      id: data.id,
      is_active: data.is_active,
      provider: 'slack',
      config: config,
    });
    setLoading(false);
  };

  return (
    <div className='mt-8 bg-gray-50 px-10 py-7'>
      <div className='font-bold'>Slack Notification Settings</div>
      <form onSubmit={handleSubmit(onSave)}>
        <div className='py-2'>
          <CheckboxController
            control={control}
            name='summaryReport'
            className='!items-start justify-start'
            inputClassName='mt-1'
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                <div className={classNames('text-sm font-medium')}>Summary Reports</div>
                <div className='text-sm font-normal text-gray-500'>
                  Reports summarizing the activity in the past month. Includes case statistics, risks detected
                  and entities detected.
                </div>
              </div>
            }
          />
          <div className={classNames('pl-6 pt-3', { hidden: !summaryReport })}>
            <RadioButtonsController control={control} name='summaryReportType'>
              <RadioButton labelText='Monthly' value='monthly' />
              <RadioButton labelText='Weekly' value='weekly' />
            </RadioButtonsController>
          </div>
        </div>
        <div className='py-2'>
          <CheckboxController
            control={control}
            name='fileUploads'
            className='!items-start justify-start'
            inputClassName='mt-1'
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                <div className={classNames('text-sm font-medium')}>File Uploads</div>
                <div className='text-sm font-normal text-gray-500'>
                  Notification sent after an uploaded file has been successfully processed.
                </div>
              </div>
            }
          />
        </div>
        <div className='py-2'>
          <CheckboxController
            control={control}
            name='riskDetectionNotifications'
            className='!items-start justify-start'
            inputClassName='mt-1'
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                <div className={classNames('text-sm font-medium')}>Risk Detection Notifications</div>
                <div className='text-sm font-normal text-gray-500'>
                  Notification sent when any entity (address, transaction or customer) of the following risks
                  is detected by Merkle Science. The entity can be submitted through APIs or through the
                  manual upload.
                </div>
              </div>
            }
          />
          <div
            className={classNames('flex gap-4 pl-6 pt-3', {
              hidden: !riskDetectionNotifications,
            })}>
            <CheckboxController control={control} name='riskLevels.5' labelText='Critical' />
            <CheckboxController control={control} name='riskLevels.4' labelText='High' />
            <CheckboxController control={control} name='riskLevels.3' labelText='Medium' />
            <CheckboxController control={control} name='riskLevels.2' labelText='Caution' />
            <CheckboxController control={control} name='riskLevels.1' labelText='Info' />
          </div>
          <div
            className={classNames('py-4 pl-6', {
              hidden: !riskDetectionNotifications,
            })}>
            <CheckboxController
              control={control}
              name='alertItemNotifications'
              className='!items-start justify-start'
              inputClassName='mt-1'
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div className={classNames('text-sm font-medium')}>Exposure change detection</div>
                  <div className='text-sm font-normal text-gray-500'>
                    Notifies when there is a change in exposure values or new sub item in Alert is created
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <Button type='submit' className='ml-auto flex' disabled={loading}>
          Save Configuration
        </Button>
      </form>
    </div>
  );
};

export default SlackForm;
