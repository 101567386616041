import * as React from 'react';
import { Info, Plus } from '@phosphor-icons/react';

import { useRouter } from '../../../modules/router/RouterProvider';
import { RiskLevel } from '../../../utils/constants/constants';
import { addressRules, transactionRules } from '../../../utils/constants/rules';
import { customTagsApi } from '../../../api/settings/customTags';
import { IPolicyResponse, IRuleDetailsResponse, IRuleCondition } from '../../../api/dtos/policies';
import { policyApi } from '../../../api/policies';

import { BasicBadge, Checkbox, Input, RiskBadge, Button, ConfirmationModal } from '../../../ui';
import SingleSelect, { IOption } from '../../../components/ui/components/Select/SingleSelect';

import Rule from '../../Risks/Rule';

import EmptyState from '@/assets/empty.svg';
import RuleEditor from './RuleEditor';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { AxiosError } from 'axios';
import { mapRuleConditions } from '../../../utils/helpers/rules';

interface Props {
  policyDetails: IPolicyResponse;
  ruleDetails?: IRuleDetailsResponse;
}

const riskLevelOptions = RiskLevel.map((riskLevel) => ({
  label: riskLevel.label,
  value: String(riskLevel.value),
}));

const AddEditRule: React.FC<Props> = ({ policyDetails, ruleDetails }) => {
  const { navigate, getParams } = useRouter();
  const params = getParams();
  const policyId = params.policyId;
  const ruleId = params.ruleId;
  const isEditing = !!ruleId;

  const [ruleName, setRuleName] = React.useState('');
  const [riskLevel, setRiskLevel] = React.useState('');
  const [customTag, setCustomTag] = React.useState('');
  const [actionKeyword, setActionKeyword] = React.useState('');
  const [ruleStatus, setRuleStatus] = React.useState(true);
  const [ruleConditions, setRuleConditions] = React.useState<IRuleCondition[]>([]);
  const [customTagOptions, setCustomTagOptions] = React.useState<IOption[]>([]);
  const [ruleStatusConfirm, setRuleStatusConfirm] = React.useState(false);
  const [savingRule, setSavingRule] = React.useState('load');

  React.useEffect(() => {
    if (isEditing) {
      setRuleStatusConfirm(true);
    }
    const fetchCustomTags = async () => {
      const res = await customTagsApi.getCustomTags({
        lite: true,
        is_active: true,
        is_in_custom_list: false,
      });

      setCustomTagOptions(
        res.data.results.map((val) => ({
          label: val.name,
          value: String(val.id),
        }))
      );
    };

    fetchCustomTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (ruleDetails?.rule_name) {
      setRuleName(ruleDetails.rule_name);
      setRiskLevel(String(ruleDetails.alert_level));
      setCustomTag(String(ruleDetails.custom_tag));
      setActionKeyword(ruleDetails.action);
      setRuleStatus(ruleDetails.is_enabled);
      setRuleConditions(ruleDetails.rule_conditions);
    } else {
      setRuleName('');
      setRiskLevel('');
      setCustomTag('');
      setActionKeyword('');
      setRuleStatus(true);
      setRuleConditions([]);
    }
  }, [ruleDetails]);

  const [isAddingCondition, setIsAddingCondition] = React.useState(false);
  const [currentRule, setCurrentRule] = React.useState<IOption>();

  const addCondition = (ruleConditionType) => {
    const newCondition: IRuleCondition = { rule_condition_type: Number(ruleConditionType) };

    setRuleConditions([...ruleConditions, newCondition]);
    setCurrentRule(null);
    setIsAddingCondition(false);
  };

  const removeCondition = (index) => {
    const newList = [...ruleConditions];
    newList.splice(index, 1);
    setRuleConditions(newList);
  };

  const ruleConditionOptions = React.useMemo(() => {
    const selectedConditions = policyDetails?.type === 0 ? transactionRules : addressRules;
    const rulesIndexes = Object.keys(selectedConditions)
      .filter((value) => {
        const itemIndex = Number(value);
        if (policyDetails?.category === 1) {
          if (isEditing) {
            if (
              ruleDetails.rule_conditions.find(
                (data) => data.rule_condition_type > 3 && data.rule_condition_type < 7
              )
            ) {
              return itemIndex > 3 && itemIndex < 7;
            } else {
              return itemIndex < 4 || (itemIndex > 17 && itemIndex < 22);
            }
          } else {
            return itemIndex < 7 || (itemIndex > 17 && itemIndex < 22);
          }
        } else if (policyDetails?.category === 3) {
          return itemIndex > 21;
        }
        return itemIndex > 6 && itemIndex < 18;
      })
      .map((v) => Number(v));
    if (policyDetails?.type === 0) {
      return rulesIndexes.map((key) => ({
        value: key.toString(),
        label: transactionRules[key],
      }));
    } else {
      return rulesIndexes.map((key) => ({
        value: key.toString(),
        label: addressRules[key],
      }));
    }
  }, [policyDetails?.type, policyDetails?.category, isEditing, ruleDetails?.rule_conditions]);

  const renderRuleForm = (r, i, len) => {
    return (
      <>
        <div className='mb-3'>
          {len > 1 && (
            <span className='rounded border bg-gray-50 px-1 py-0.5 text-2xs font-medium uppercase tracking-wider text-gray-600'>
              Condition {i + 1}
            </span>
          )}
        </div>
        <div className='flex'>
          <div className='w-11/12'>
            <RuleEditor
              ruleCondition={r}
              type={policyDetails?.type_verbose?.toLocaleLowerCase() as 'address' | 'transaction'}
              editedConditions={ruleConditions}
              setEditedConditions={setRuleConditions}
              index={i}
            />
          </div>
          <div className='w-1/12'>
            {(!isEditing || !ruleDetails?.rule_conditions?.includes(r)) && (
              <Button type='button' variant='tertiary' onClick={() => removeCondition(i)}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const { mutate: updatePolicy } = useMutation(policyApi.updatePolicyRuleDetails, {
    onSuccess() {
      toast.success('Rule updated successfully');
      navigate(`/settings/workspace/policies/${policyId}`);
    },
    onError(d: AxiosError) {
      toast.error(getErrorMessage(d));
    },
  });

  const { mutate: createPolicy } = useMutation(policyApi.createPolicyRuleDetails, {
    onSuccess() {
      toast.success('Rule created successfully');
      navigate(`/settings/workspace/policies/${policyId}`);
    },
    onError(d: AxiosError) {
      toast.error(getErrorMessage(d));
    },
  });

  const handleSaveRule = async () => {
    if (isEditing) {
      updatePolicy({
        id: policyId,
        ruleId: ruleDetails.uuid,
        ruleDetails: {
          action: actionKeyword,
          alert_level: Number(riskLevel),
          custom_tag: Number(customTag),
          is_enabled: ruleStatus,
          rule_category: policyDetails?.category,
          rule_name: ruleName,
          rule_type: policyDetails?.category,
          uuid: ruleDetails.uuid,
          rule_conditions: mapRuleConditions(ruleConditions),
        },
      });
    } else {
      createPolicy({
        id: policyId,
        ruleDetails: {
          action: actionKeyword,
          alert_level: Number(riskLevel),
          custom_tag: customTag !== '' ? Number(customTag) : null,
          is_enabled: ruleStatus,
          rule_category: policyDetails?.category,
          rule_name: ruleName,
          rule_type: policyDetails?.category,
          rule_conditions: mapRuleConditions(ruleConditions),
        },
      });
    }
  };

  return (
    <div>
      <div className='relative flex gap-4 border-b p-4 py-8 text-2xs'>
        <div className='grow'>
          <Input
            id='rule-name'
            type='text'
            labelText='Rule Name'
            value={ruleName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRuleName(e.target.value)}
            className='flex h-full flex-col'
            labelClassNames='text-xs font-medium'
            inputClassNames='text-xs p-1.5'
            required
          />
        </div>
        <div className='grow-[2]'>
          <SingleSelect
            options={riskLevelOptions.slice(0, 5)}
            handleChange={(value) => setRiskLevel(value?.value)}
            value={riskLevelOptions.find((option) => option.value === riskLevel)}
            label='Risk Level'
            labelClassName='text-gray-800 !text-xs font-medium'
            controlClassPaddingY='!py-1'
            isCloseHidden={true}
            className='!mt-0'
            parentClassName='flex h-full flex-col'
            getCustomLabel={(val) => <RiskBadge risk={Number(val.value)} className='!text-3xs' />}
            getCustomOptionLabel={(val) => <RiskBadge risk={Number(val.value)} className='!text-2xs' />}
          />
        </div>
        <div className=''>
          <label className={'block text-xs font-medium leading-default text-gray-800'}>Policy Type</label>
          <div className='mt-1 rounded-md border px-3 py-1 text-xs shadow-sm'>
            <BasicBadge className='bg-gray-200 !text-2xs'>{policyDetails?.type_verbose}</BasicBadge>
          </div>
        </div>
        <div className='grow-[2]'>
          <label className={'block text-xs font-medium leading-default text-gray-800'}>Policy Category</label>
          <div className='mt-1 rounded-md border px-3 py-1 text-xs shadow-sm'>
            <BasicBadge className='bg-gray-200 !text-2xs'>{policyDetails?.category_verbose}</BasicBadge>
          </div>
        </div>
        <div className='grow-[2]'>
          {isEditing ? (
            <div className='flex h-full flex-col'>
              <div className='text-xs font-medium text-gray-800'>Custom Tag</div>
              <div className='mt-1 rounded-md border p-1.5'>
                <BasicBadge
                  className={`${customTag !== 'null' ? 'bg-purple-100' : ''} py-0 !text-2xs text-purple-700`}>
                  {customTagOptions.find((option) => option.value === customTag)?.label}
                </BasicBadge>
              </div>
            </div>
          ) : (
            <SingleSelect
              options={customTagOptions}
              handleChange={(value) => setCustomTag(value?.value)}
              value={customTagOptions.find((option) => option.value === customTag)}
              label='Custom Tag'
              labelClassName='text-gray-800 text-xs font-medium'
              placeholder='Select Option (Optional)'
              parentClassName='flex h-full flex-col justify-between'
              controlClassPaddingY='!py-1'
              getCustomLabel={() => (
                <BasicBadge className='bg-purple-100 !text-2xs text-purple-900'>
                  {customTagOptions.find((option) => option.value === customTag).label}
                </BasicBadge>
              )}
              disabled={isEditing}
            />
          )}
        </div>
        <div>
          <Input
            id='action-keyword'
            type='text'
            labelText='Action Keyword'
            value={actionKeyword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActionKeyword(e.target.value)}
            className='flex h-full flex-col'
            labelClassNames='text-xs font-medium'
            inputClassNames='text-xs p-1.5'
            disabled={isEditing}
          />
        </div>
        <div className=''>
          <span className='text-xs font-medium'>Rule Status</span>
          <div className='mt-2.5'>
            <Checkbox
              checked={ruleStatus}
              labelText='Live'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isEditing) {
                  if ((e.target as HTMLInputElement).checked === true) {
                    setRuleStatus((e.target as HTMLInputElement).checked);
                  } else {
                    setSavingRule('edit');
                    setRuleStatusConfirm(true);
                  }
                } else {
                  setRuleStatus((e.target as HTMLInputElement).checked);
                }
              }}
            />
          </div>
        </div>
        <div className='absolute -top-12 right-3'>
          <Button
            type='button'
            buttonSize='md'
            variant='primary'
            onClick={() => {
              if (isEditing) {
                setSavingRule('save');
                setRuleStatusConfirm(true);
              } else {
                handleSaveRule();
              }
            }}
            // disabled={!isRuleUpdated}>
          >
            Save Rule
          </Button>
        </div>
      </div>
      <div className='p-4'>
        {ruleConditions.length === 0 ? (
          <div>
            {isAddingCondition ? (
              <div>
                <p className='mb-2 text-sm font-medium'>Condition</p>
                <div className='mt-5'>
                  <SingleSelect
                    options={ruleConditionOptions}
                    value={currentRule}
                    handleChange={(val) => addCondition(val.value)}
                    placeholder='Select Condition'
                    labelClassName='text-gray-800'
                    getCustomOptionLabel={(option) => {
                      return (
                        <div className='font-medium text-black'>
                          <Rule
                            ruleType={Number(option.value)}
                            type={
                              policyDetails?.type_verbose?.toLocaleLowerCase() as 'address' | 'transaction'
                            }
                            setLabel={true}
                          />
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='flex items-center justify-center py-10 text-center'>
                <div>
                  <img alt='Empty state' src={EmptyState} width='140' className='mx-auto' />
                </div>
                <Button
                  type='button'
                  variant='tertiary'
                  onClick={() => setIsAddingCondition(true)}
                  iconStart={<Plus />}
                  className='mt-3'>
                  Add Condition
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p className='mb-2 text-sm font-medium'>Condition</p>
            {ruleConditions.map((r, i) => (
              <div key={i}>
                {renderRuleForm(r, i, ruleConditions.length)}
                {i !== ruleConditions.length - 1 && <div className='py-3 text-xs text-gray-400'>AND</div>}
              </div>
            ))}
            {isAddingCondition ? (
              <div className='mt-5'>
                <SingleSelect
                  options={ruleConditionOptions}
                  value={currentRule}
                  handleChange={(val) => addCondition(val.value)}
                  placeholder='Select Condition'
                  labelClassName='text-gray-800'
                  getCustomOptionLabel={(option) => {
                    return (
                      <div className='font-medium text-black'>
                        <Rule
                          ruleType={Number(option.value)}
                          type={policyDetails?.type_verbose?.toLocaleLowerCase() as 'address' | 'transaction'}
                          setLabel={true}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            ) : (
              <Button
                type='button'
                variant='tertiary'
                onClick={() => setIsAddingCondition(true)}
                iconStart={<Plus />}
                className='mt-3'>
                Add Condition
              </Button>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal
        onConfirm={() => {
          if (savingRule === 'save') {
            handleSaveRule();
          } else if (savingRule === 'edit') {
            setRuleStatus(false);
          }
          setRuleStatusConfirm(false);
        }}
        open={!!ruleStatusConfirm && isEditing}
        data={(ruleStatusConfirm && isEditing) || null}
        onCancel={() => setRuleStatusConfirm(false)}
        title={savingRule === 'save' ? 'Save Rule' : 'Edit Rule'}
        body={
          savingRule ? (
            'Updating the rule or deactivating the rule will cause all active alerts associated with this rule to become inactive. Are you sure you want to proceed?'
          ) : (
            <div className='flex justify-start gap-3'>
              <Info size={25} />
              <p>
                Updating the rule or deactivating the rule will cause all active alerts associated with this
                rule to become inactive.
              </p>
            </div>
          )
        }
        cancelButton={'No'}
        confirmButton={savingRule === 'load' ? 'Ok' : 'Yes'}
        showCancelButton={savingRule !== 'load'}
      />
    </div>
  );
};

export default AddEditRule;
