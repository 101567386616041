/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from '../../../src/modules/router/RouterProvider';
import Table, { ITableRow } from '../../../src/ui/components/Table/Table';
import { useSearchWithType, useSearch } from '../../../src/api/search';
import { useGetCustomerData } from '../dashboard/customers/CustomerData';
import { useGetAddressData } from '../dashboard/addresses/AddressData';
import { useGetTransactionData } from '../dashboard/transactions/TransactionData';
import { useGetCaseData } from '../dashboard/cases/CaseData';
import { useEffect, useMemo, useState } from 'react';
import { sanitizeAndEncode } from '../../utils/helpers/helperFunctions';
import DashboardTabs from '../../components/DashboardTabs/DashboardTabs';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { ArrowsInSimple, ArrowsOutSimple } from '@phosphor-icons/react';
import CurrencyBadge from '../../components/ui/components/Badge/CurrencyBadge';
import { useScreenApi } from '../../utils/helpers/apiHelpers';
import { isLiteCurrency } from '../../utils/helpers/currency';
import EmptyState from '../../ui/components/States/Empty';
import { Button } from '../../../src/ui';
import { useAuth } from '../../modules/auth';
import TransactionPopover from '../../components/AddressTransaction/TransactionPopover';
import AddressPreview from '../../components/Address/AddressPreview';

const SearchPage = () => {
  const { setTabState } = useAuth();
  const { getQueryParams, setQueryParams, navigate } = useRouter();
  const { q, type } = getQueryParams();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [addressPreview, setAddressPreview] = useState(false);
  const [transactionPreview, setTransactionPreview] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({
    id: '',
    currency: 0,
  });
  const screenApi = useScreenApi();
  const { isLoading, data } = useSearch({ query: sanitizeAndEncode(q?.trim())?.replace(/%3A/g, ':') });
  const searchData = data?.data;
  const addressesCount = data?.data?.addresses?.count ?? 0;
  const transactionsCount = data?.data?.transactions?.count ?? 0;
  const casesCount = data?.data?.cases?.count ?? 0;
  const customersCount = data?.data?.customers?.count ?? 0;
  const sanctionedAddressesCount = data?.data?.un_screened_addresses?.length ?? 0;
  const sanctionedTransactionsCount = data?.data?.un_screened_transactions?.length ?? 0;

  const pageTypeMemo = useMemo(() => {
    if (['addresses', 'transactions', 'customers', 'cases'].includes(type)) {
      return type;
    }

    if (isLoading) {
      return null;
    }

    if (addressesCount > 0) {
      return 'addresses';
    }

    if (transactionsCount > 0) {
      return 'transactions';
    }

    if (customersCount > 0) {
      return 'customers';
    }

    if (casesCount > 0) {
      return 'cases';
    }

    if (sanctionedAddressesCount > 0) {
      return 'addresses';
    }

    if (sanctionedTransactionsCount > 0) {
      return 'transactions';
    }

    return null;
  }, [
    type,
    isLoading,
    addressesCount,
    sanctionedAddressesCount,
    transactionsCount,
    sanctionedTransactionsCount,
    customersCount,
    casesCount,
  ]);
  const sanctionedType =
    pageTypeMemo === 'addresses'
      ? sanctionedAddressesCount > 0
        ? 'un_screened_addresses'
        : null
      : pageTypeMemo === 'transactions'
        ? sanctionedTransactionsCount > 0
          ? 'un_screened_transactions'
          : null
        : null;

  useEffect(() => {
    if (pageTypeMemo) {
      setQueryParams({
        q: q,
        type: pageTypeMemo,
      });
      if (searchData?.[pageTypeMemo]?.results?.length < searchData?.[pageTypeMemo]?.count) {
        setHasNextPage(true);
      } else setHasNextPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTypeMemo, searchData]);

  useEffect(() => {
    setTabState({ isTabVisible: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!q) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  const pageData = useSearchWithType(
    { query: q, type: pageTypeMemo },
    {
      //TODO: Specify the response type
      onSuccess(d: any) {
        const urlObject = new URL(d?.pages?.[0].request?.responseURL);
        const pathSegments = urlObject.pathname.split('/');
        const resultPageType = pathSegments.filter((segment) => segment).pop();
        searchData[resultPageType].results = [
          ...searchData[resultPageType].results,
          ...d.pages[d?.pages?.length - 1].data[resultPageType].results,
        ];

        if (searchData?.[resultPageType]?.results?.length < searchData?.[resultPageType]?.count) {
          setHasNextPage(true);
        } else setHasNextPage(false);
      },
      enabled: false,
    }
  );

  const addressesData = useGetAddressData({
    addresses: pageTypeMemo === 'addresses' ? searchData?.addresses?.results : null,
    type: 'search',
  });
  const customerData = useGetCustomerData({
    customers: pageTypeMemo === 'customers' ? searchData?.customers?.results : null,
    type: 'search',
  });
  const transactionData = useGetTransactionData({
    transactions: pageTypeMemo === 'transactions' ? searchData?.transactions?.results : null,
    type: 'search',
  });
  const casesData = useGetCaseData({
    cases: pageTypeMemo === 'cases' ? searchData?.cases?.results : null,
    type: 'search',
  });

  const tableData =
    pageTypeMemo === 'customers'
      ? customerData
      : pageTypeMemo === 'addresses'
        ? addressesData
        : pageTypeMemo === 'transactions'
          ? transactionData
          : casesData;

  const rows: ITableRow[] = tableData?.rowData;
  const headerData = tableData?.headerData;

  const handleScreen = (currency: number, identifier: string, screenType: string) => {
    if (screenType === 'unscreened') {
      if (pageTypeMemo === 'transactions') {
        setTransactionPreview(true);
      }
      if (pageTypeMemo === 'addresses') {
        setAddressPreview(true);
      }
      setCurrentEntity({
        id: identifier,
        currency,
      });
      return;
    }
    screenApi({
      identifier,
      currency,
      entityType: pageTypeMemo as 'addresses' | 'transactions',
    });
  };

  return (
    <>
      {!isLoading && (
        <DashboardTabs
          isSearchPage={true}
          addressesCount={addressesCount}
          transactionsCount={transactionsCount}
          casesCount={casesCount}
          customersCount={customersCount}
          sanctionedAddressesCount={sanctionedAddressesCount}
          sanctionedTransactionsCount={sanctionedTransactionsCount}
        />
      )}
      {(isLoading || rows?.length > 0) && (
        <Table
          isLoading={isLoading}
          tab={pageTypeMemo}
          titleComponent={
            <h4 className={`px-3 text-2xs font-medium text-gray-500`}>
              {pageTypeMemo === 'addresses' || pageTypeMemo === 'transactions' ? (
                <>
                  <span className='uppercase'>Previously Screened {pageTypeMemo}</span>
                  <span className='text-gray-400'>{' " ' + q + ' "'}</span>
                </>
              ) : (
                <>
                  <span>{pageTypeMemo?.toUpperCase()} associated with</span>
                  <span className='text-gray-400'>{' " ' + q + ' "'}</span>
                </>
              )}
            </h4>
          }
          isSearchPage={true}
          isHeaderVisible={true}
          className='mt-1'
          headerData={headerData}
          rows={rows}
          hasNextPage={hasNextPage}
          isFetchingNextPage={pageData.isFetchingNextPage}
          height={
            (pageTypeMemo === 'addresses' || pageTypeMemo === 'transactions') &&
            searchData?.[sanctionedType]?.length > 0
              ? 350
              : null
          }
          fetchNextPage={pageData.fetchNextPage}
          disableTitleUpperCase
          key={pageTypeMemo}
        />
      )}
      {sanctionedType && (
        <>
          {(() => {
            const filteredItems = searchData[sanctionedType].filter((x) => !isLiteCurrency(x.currency));
            return filteredItems.length > 0 ? (
              <div className='pt-4'>
                <div className='px-3 text-2xs font-semibold uppercase text-gray-500'>
                  Unscreened {pageTypeMemo}
                </div>
                <div className='flex origin-top flex-wrap pt-4'>
                  {filteredItems?.map((item, index) => (
                    <div key={index} className='mb-4 w-full max-w-[25%] px-3'>
                      <div
                        className='flex w-full cursor-pointer flex-col gap-2 rounded-lg bg-gray-50 p-3 hover:bg-gray-200'
                        onClick={() => handleScreen(item.currency, item.identifier, 'unscreened')}>
                        <CurrencyBadge currency={item.currency} className='w-fit' />
                        <code className='whitespace-pre-wrap break-words text-2xs text-blue-500'>
                          {item.identifier}
                        </code>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null;
          })()}
          {(() => {
            const filteredItems = searchData[sanctionedType].filter((x) => isLiteCurrency(x.currency));
            return filteredItems.length > 0 ? (
              <Disclosure as='div' className='p-6' defaultOpen={rows?.length === 0 ? true : false}>
                {({ open }) => (
                  <>
                    <DisclosureButton className='group relative flex w-full items-center justify-between rounded-lg bg-gray-100 px-3 py-2 shadow-md'>
                      <div className='text-2xs font-medium text-gray-500'>Sanctions Screening Only</div>
                      <div className='flex gap-1 rounded-lg bg-blue-100 p-2 text-sm'>
                        <div className=''>{open ? 'Collapse' : 'Expand'}</div>
                        {open ? <ArrowsInSimple size={20} /> : <ArrowsOutSimple size={20} />}
                      </div>
                    </DisclosureButton>
                    <Transition
                      as='div'
                      enter='duration-200 ease-out'
                      enterFrom='opacity-0 -translate-y-6'
                      enterTo='opacity-100 translate-y-0'
                      leave='duration-300 ease-out'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 -translate-y-6'>
                      <DisclosurePanel className='flex origin-top flex-wrap bg-gray-100 pt-4 transition'>
                        {filteredItems.map((item, index) => (
                          <div key={index} className='mb-4 w-full max-w-[25%] px-3'>
                            <div
                              className='flex w-full cursor-pointer flex-col gap-2 rounded-lg bg-white p-3 hover:bg-gray-200'
                              onClick={() => handleScreen(item.currency, item.identifier, 'sanctioned')}>
                              <CurrencyBadge currency={item.currency} className='w-fit' />
                              <code className='whitespace-pre-wrap break-words text-2xs text-blue-500'>
                                {item.identifier}
                              </code>
                            </div>
                          </div>
                        ))}
                      </DisclosurePanel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ) : null;
          })()}
        </>
      )}
      {!isLoading &&
        addressesCount === 0 &&
        transactionsCount === 0 &&
        customersCount === 0 &&
        casesCount === 0 &&
        sanctionedAddressesCount === 0 &&
        sanctionedTransactionsCount === 0 && (
          <div className='flex flex-col items-center'>
            <EmptyState />
            <Button onClick={() => navigate('/dashboard')} variant='primary'>
              Go Home
            </Button>
          </div>
        )}
      <TransactionPopover
        id={currentEntity?.id}
        open={transactionPreview}
        setOpen={setTransactionPreview}
        handleScreen={(id: string) =>
          screenApi({
            identifier: id,
            currency: currentEntity.currency,
            entityType: pageTypeMemo as 'addresses' | 'transactions',
          })
        }
        currency={currentEntity?.currency}
      />
      <AddressPreview
        id={currentEntity?.id}
        currency={currentEntity?.currency}
        open={addressPreview}
        setOpen={setAddressPreview}
      />
    </>
  );
};

export default SearchPage;
