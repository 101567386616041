import { FC } from 'react';
import { ITokenSummaryResult } from '../../../api/dtos/address';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import { formatDateShort } from '../../../utils/helpers/date';
import ReportTable from '../ReportTable';
import { getTokenVerboseByCurrencyId } from '../../../utils/helpers/currency';
import { useRouter } from '../../../modules/router/RouterProvider';

interface IErcTokensReportProps {
  tokenSummary: ITokenSummaryResult[];
  currency: number;
  part?: number;
}

const ErcTokensReport: FC<IErcTokensReportProps> = ({ tokenSummary, currency, part }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  if (!tokenSummary) return null;

  const tableData = tokenSummary.map((token) => ({
    label: 'ERC Token',
    value: [
      {
        label: 'Token Name',
        value: token.token,
      },
      {
        label: 'Balance',
        value: <CurrencyValue value={token.balance} currency={token.token} />,
      },
      {
        label: 'Received Amount',
        value: <CurrencyValue value={token.total_incoming_value} currency={token.token} />,
      },
      {
        label: 'Sent Amount',
        value: <CurrencyValue value={token.total_outgoing_value} currency={token.token} />,
      },
      {
        label: 'Earliest Transaction',
        value: formatDateShort(token.earliest_transaction_time, timezone),
      },
      {
        label: 'Latest Transaction',
        value: formatDateShort(token.latest_transaction_time, timezone),
      },
    ],
  }));

  return (
    <ReportTable tableData={tableData} title={`Part ${part}: ${getTokenVerboseByCurrencyId(currency)}`} />
  );
};

export default ErcTokensReport;
