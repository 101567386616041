import * as React from 'react';

import Dropdown from '../../../ui/components/Dropdown/Dropdown';
import { IAddressResponse, ITokenSummaryResult } from '../../../api/dtos/address';
import { formatDateShort } from '../../../utils/helpers/date';

import Table from '../../../ui/components/Table/Table';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { useQuery } from 'react-query';
import { addressApi } from '../../../api/address';
import { isTokenCurrency } from '../../../utils/helpers/currency';
import { useAuth } from '../../../modules/auth';
import { useAddressGetAddressTokenSummaryLite } from '../../../api/addressPreview';

interface Props {
  address: IAddressResponse;
  isPreview?: boolean;
}

const AddressSummary: React.FC<Props> = ({ address, isPreview }) => {
  const [type, setType] = React.useState<'digital' | 'usd'>('digital');
  const tokenCurrency = isTokenCurrency(address.currency);
  const timezone = useAuth()?.state?.userProfile?.timezone;

  const addressTokenQueryFull = useQuery(
    ['addressApi.getTokenSummary', address.id],
    () => addressApi.getTokenSummary(address.id),
    {
      enabled: tokenCurrency && !isPreview,
    }
  );

  const addressTokenQueryLite = useAddressGetAddressTokenSummaryLite(address.identifier, address.currency, {
    enabled: tokenCurrency && isPreview,
  });

  const addressTokenQuery = isPreview ? addressTokenQueryLite : addressTokenQueryFull;

  const { data: tokenSummary, isLoading: isTokenSummaryLoading } = addressTokenQuery;

  // Hide summary amount and dropdown for these currencies
  const isSummaryAmountHidden = [4, 13, 14].includes(address.currency);

  const headerData = [
    'Token',
    'Balance',
    ...(isSummaryAmountHidden ? [] : ['Total Incoming Value', 'Total Outgoing Value']),
    'Earliest Transaction',
    'Latest Transaction',
  ];

  const data: ITokenSummaryResult[] = tokenCurrency
    ? tokenSummary?.data?.results
    : [
        {
          token: address.currency_short,
          balance: address.balance,
          total_incoming_value: address.total_incoming_value,
          total_incoming_value_usd: address.total_incoming_value_usd,
          total_outgoing_value: address.total_outgoing_value,
          total_outgoing_value_usd: address.total_outgoing_value_usd,
          earliest_transaction_time: address.earliest_transaction_time,
          latest_transaction_time: address.latest_transaction_time,
        },
      ];

  const rows = data?.map((a) => ({
    id: a.token,
    data: [
      <CurrencyBadge transparent key={a.token} currency={address.currency} label={a.token} />,
      <CurrencyValue key={a.token} value={a.balance} currency={a.token} />,
      ...(isSummaryAmountHidden
        ? []
        : [
            <CurrencyValue
              key={a.token}
              value={type === 'digital' ? a.total_incoming_value : a.total_incoming_value_usd}
              currency={type === 'digital' ? a.token : 'USD'}
            />,
            <CurrencyValue
              key={a.token}
              value={type === 'digital' ? a.total_outgoing_value : a.total_outgoing_value_usd}
              currency={type === 'digital' ? a.token : 'USD'}
            />,
          ]),
      formatDateShort(a.earliest_transaction_time, timezone),
      formatDateShort(a.latest_transaction_time, timezone),
    ],
  }));

  return (
    <div className='rounded-md border border-gray-200'>
      <Table
        height={400}
        headerActions={
          !isSummaryAmountHidden && (
            <Dropdown
              value={{
                label: (
                  <span>
                    Value in <strong>{type === 'digital' ? 'Digital Asset' : 'USD'}</strong>
                  </span>
                ),
                value: type,
              }}
              options={[
                { label: 'Value in Digital Asset', value: 'digital' },
                { label: 'Value in USD', value: 'usd' },
              ]}
              onChange={(val) => setType(val.value as 'digital' | 'usd')}
              optionsContainerClass='right-0'
              customClass='z-20 pl-3 pr-8'
              optionsCustomClass='px-3'
            />
          )
        }
        headerData={headerData}
        rows={rows}
        title='Address Summary'
        isLoading={isTokenSummaryLoading}
      />
    </div>
  );
};

export default AddressSummary;
