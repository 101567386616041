import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import Layout from './Layout';
import ScreeningLoader from '../components/ui/components/Loader/ScreeningLoader';
import { useAuth } from '../modules/auth';

interface AppShellProps {
  isSearchPage?: boolean;
}

const AppShell: FC<AppShellProps> = ({ isSearchPage = false }) => {
  const { state } = useAuth();

  return (
    <>
      {state.screeningLoader && <ScreeningLoader />}
      <Header />
      <Layout heightOffset={isSearchPage ? 0 : 62}>
        <Outlet />
      </Layout>
    </>
  );
};

export default AppShell;
