import { ICurrencyType, IRiskLevel } from './types';

export const entityOptions = [
  {
    value: 'Law Enforcement',
    label: 'Law Enforcement',
    selected: false,
    color: '#0c4a6e',
    bgColor: 'bg-sky-900',
  },
  { value: 'Donation', label: 'Donation', selected: false, color: '#0369a1', bgColor: 'bg-sky-700' },
  { value: 'Defi', label: 'Defi', selected: false, color: '#0ea5e9', bgColor: 'bg-sky-500' },
  {
    value: 'Smart Contract Platform',
    label: 'Smart Contract Platform',
    selected: false,
    color: '#7dd3fc',
    bgColor: 'bg-sky-300',
  },
  { value: 'Service', label: 'Service', selected: false, color: '#6366F1', bgColor: 'bg-indigo-500' },
  { value: 'Exchange', label: 'Exchange', selected: false, color: '#8b5cf6', bgColor: 'bg-violet-500' },
  { value: 'Coin Mixer', label: 'Coin Mixer', selected: false, color: '#4c1d95', bgColor: 'bg-violet-900' },
  { value: 'Gambling', label: 'Gambling', selected: false, color: '#6d28d9', bgColor: 'bg-violet-700' },
  { value: 'Mining Pool', label: 'Mining Pool', selected: false, color: '#a78bfa', bgColor: 'bg-violet-400' },
  { value: 'Sanctions', label: 'Sanctions', selected: false, color: '#831843', bgColor: 'bg-pink-900' },
  { value: 'Darknet', label: 'Darknet', selected: false, color: '#be185d', bgColor: 'bg-pink-700' },
  { value: 'Scam', label: 'Scam', selected: false, color: '#ec4899', bgColor: 'bg-pink-500' },
  { value: 'Extortion', label: 'Extortion', selected: false, color: '#f9a8d4', bgColor: 'bg-pink-300' },
  {
    value: 'High Risk Organization',
    label: 'High Risk Organization',
    selected: false,
    color: '#be123c',
    bgColor: 'bg-rose-700',
  },
  { value: 'Malware', label: 'Malware', selected: false, color: '#f43f5e', bgColor: 'bg-rose-500' },
  { value: 'Theft', label: 'Theft', selected: false, color: '#fb7185', bgColor: 'bg-rose-400' },
  { value: 'Others', label: 'Others', selected: false, color: '#d3d3d3', bgColor: 'bg-gray-300' },
];

export const RiskLevel: IRiskLevel[] = [
  {
    value: 5,
    label: 'Critical',
    selected: false,
    bgColor: 'bg-red-700',
    textColor: 'text-white',
    color: '#b91c1c',
  },
  {
    value: 4,
    label: 'High',
    selected: false,
    bgColor: 'bg-red-500',
    textColor: 'text-white',
    color: '#ef4444',
  },
  {
    value: 3,
    label: 'Medium',
    selected: false,
    bgColor: 'bg-red-400',
    textColor: 'text-white',
    color: '#f87171',
  },
  {
    value: 2,
    label: 'Caution',
    selected: false,
    bgColor: 'bg-amber-400',
    textColor: 'text-gray-800',
    color: '#fbbf24',
  },
  {
    value: 1,
    label: 'Info',
    selected: false,
    bgColor: 'bg-amber-200',
    textColor: 'text-gray-800',
    color: '#fde68a',
  },
  {
    value: 0,
    label: 'No Risk',
    selected: false,
    bgColor: 'bg-gray-100',
    textColor: 'text-gray-800',
    color: '#f3f4f6',
  },
];

export const Currencies: ICurrencyType[] = [
  {
    value: 0,
    label: 'Bitcoin',
    currency_short: 'BTC',
  },
  {
    value: 1,
    label: 'Ethereum',
    currency_short: 'ETH',
  },
  {
    value: 2,
    label: 'Litecoin',
    currency_short: 'LTC',
  },
  {
    value: 3,
    label: 'Bitcoin Cash',
    currency_short: 'BCH',
  },
  {
    value: 4,
    label: 'Ripple',
    currency_short: 'XRP',
  },
  {
    value: 5,
    label: 'Bitcoin SV',
    currency_short: 'BSV',
    deprecated: true,
  },
  {
    value: 6,
    label: 'Dogecoin',
    currency_short: 'DOGE',
  },
  {
    value: 7,
    label: 'Zilliqa',
    currency_short: 'ZIL',
  },
  {
    value: 8,
    label: 'Binance Smart Chain',
    currency_short: 'BNB',
  },
  {
    value: 9,
    label: 'Polygon',
    currency_short: 'MATIC',
  },
  {
    value: 10,
    label: 'Tron',
    currency_short: 'TRX',
  },
  {
    value: 11,
    label: 'Cardano',
    currency_short: 'ADA',
  },
  {
    value: 12,
    label: 'Polkadot',
    currency_short: 'DOT',
  },
  {
    value: 13,
    label: 'Stellar',
    currency_short: 'XLM',
  },
  {
    value: 14,
    label: 'Hedera',
    currency_short: 'HBAR',
  },
  {
    value: 15,
    label: 'Cronos',
    currency_short: 'CRO',
  },
  {
    value: 16,
    label: 'Optimism',
    currency_short: 'OP',
  },
  {
    value: 17,
    label: 'Arbitrum',
    currency_short: 'ARB',
  },
  {
    value: 18,
    label: 'Fantom',
    currency_short: 'FTM',
  },
  {
    value: 19,
    label: 'Monero',
    currency_short: 'XMR',
  },
  {
    value: 20,
    label: 'Cosmos',
    currency_short: 'COSMOS',
  },
  {
    value: 21,
    label: 'Zcash',
    currency_short: 'ZEC',
  },
  {
    value: 22,
    label: 'Ethereum Classic',
    currency_short: 'ETC',
  },
  {
    value: 23,
    label: 'Dash',
    currency_short: 'DASH',
  },
  {
    value: 24,
    label: 'Solana',
    currency_short: 'SOL',
  },
  {
    value: 25,
    label: 'Avalanche C-Chain',
    currency_short: 'AVAX',
  },
  {
    value: 26,
    label: 'Verge',
    currency_short: 'XVG',
  },
  {
    value: 27,
    label: 'Bitcoin Gold',
    currency_short: 'BTG',
  },
  {
    value: 28,
    label: 'Algorand',
    currency_short: 'ALGO',
  },
  {
    value: 29,
    label: 'Acala',
    currency_short: 'ACA',
  },
  {
    value: 30,
    label: 'Akash',
    currency_short: 'AKT',
  },
  {
    value: 31,
    label: 'Arweave',
    currency_short: 'AR',
  },
  {
    value: 32,
    label: 'Ardor',
    currency_short: 'ARDR',
  },
  {
    value: 33,
    label: 'Ark',
    currency_short: 'ARK',
  },
  {
    value: 34,
    label: 'Astar',
    currency_short: 'ASTR',
  },
  {
    value: 35,
    label: 'Band',
    currency_short: 'BAND',
  },
  {
    value: 36,
    label: 'Binance Native',
    currency_short: 'BNB',
  },
  {
    value: 37,
    label: 'Celo',
    currency_short: 'CELO',
  },
  {
    value: 38,
    label: 'Chiliz',
    currency_short: 'CHZ',
  },
  {
    value: 39,
    label: 'Nervos',
    currency_short: 'CKB',
  },
  {
    value: 40,
    label: 'Cronos Native',
    currency_short: 'CRO_NATIVE',
  },
  {
    value: 41,
    label: 'Digibyte',
    currency_short: 'DGB',
  },
  {
    value: 42,
    label: 'Multiversx',
    currency_short: 'EGLD',
  },
  {
    value: 43,
    label: 'Enjin',
    currency_short: 'ENJ',
  },
  {
    value: 44,
    label: 'Eos',
    currency_short: 'EOS',
  },
  {
    value: 45,
    label: 'Ethereum Pow',
    currency_short: 'ETHW',
  },
  {
    value: 46,
    label: 'Fetch AI',
    currency_short: 'FET',
  },
  {
    value: 47,
    label: 'Filecoin',
    currency_short: 'FIL',
  },
  {
    value: 48,
    label: 'Flow',
    currency_short: 'FLOW',
  },
  {
    value: 49,
    label: 'Flare',
    currency_short: 'FLR',
  },
  {
    value: 50,
    label: 'Moonbeam',
    currency_short: 'GLMR',
  },
  {
    value: 51,
    label: 'Helium',
    currency_short: 'HNT',
  },
  {
    value: 52,
    label: 'ICP',
    currency_short: 'ICP',
  },
  {
    value: 53,
    label: 'Icon',
    currency_short: 'ICX',
  },
  {
    value: 54,
    label: 'Injective',
    currency_short: 'INJ',
  },
  {
    value: 55,
    label: 'IOTA',
    currency_short: 'IOTA',
  },
  {
    value: 56,
    label: 'IoTex',
    currency_short: 'IOTX',
  },
  {
    value: 57,
    label: 'Iris',
    currency_short: 'IRIS',
  },
  {
    value: 58,
    label: 'Kava',
    currency_short: 'KAVA',
  },
  {
    value: 59,
    label: 'Kadena',
    currency_short: 'KDA',
  },
  {
    value: 60,
    label: 'Klaytn',
    currency_short: 'KLAY',
  },
  {
    value: 61,
    label: 'Komodo',
    currency_short: 'KMD',
  },
  {
    value: 62,
    label: 'Kusama',
    currency_short: 'KSM',
  },
  {
    value: 63,
    label: 'Lisk',
    currency_short: 'LSK',
  },
  {
    value: 64,
    label: 'Terra',
    currency_short: 'LUNA',
  },
  {
    value: 65,
    label: 'Mina',
    currency_short: 'MINA',
  },
  {
    value: 66,
    label: 'Moonriver',
    currency_short: 'MOVR',
  },
  {
    value: 67,
    label: 'Nano',
    currency_short: 'XNO',
  },
  {
    value: 68,
    label: 'Near',
    currency_short: 'NEAR',
  },
  {
    value: 69,
    label: 'Neo 3',
    currency_short: 'NEO',
  },
  {
    value: 70,
    label: 'Oasys',
    currency_short: 'OAS',
  },
  {
    value: 71,
    label: 'One',
    currency_short: 'ONE',
  },
  {
    value: 72,
    label: 'Ontology',
    currency_short: 'ONT',
  },
  {
    value: 73,
    label: 'Osmosis',
    currency_short: 'OSMO',
  },
  {
    value: 74,
    label: 'Polymesh',
    currency_short: 'POLYX',
  },
  {
    value: 75,
    label: 'QTUM',
    currency_short: 'QTUM',
  },
  {
    value: 76,
    label: 'Oasis',
    currency_short: 'ROSE',
  },
  {
    value: 77,
    label: 'Thorchain',
    currency_short: 'RUNE',
  },
  {
    value: 78,
    label: 'Ravencoin',
    currency_short: 'RVN',
  },
  {
    value: 79,
    label: 'Siacoin',
    currency_short: 'SC',
  },
  {
    value: 80,
    label: 'Shiden',
    currency_short: 'SDN',
  },
  {
    value: 81,
    label: 'Songbird',
    currency_short: 'SGB',
  },
  {
    value: 82,
    label: 'Stratis',
    currency_short: 'STRAX',
  },
  {
    value: 83,
    label: 'Stacks',
    currency_short: 'STX',
  },
  {
    value: 84,
    label: 'SUI',
    currency_short: 'SUI',
  },
  {
    value: 85,
    label: 'ZkSync',
    currency_short: 'ZKSYNC',
  },
  {
    value: 86,
    label: 'Theta',
    currency_short: 'THETA',
  },
  {
    value: 87,
    label: 'Tomo',
    currency_short: 'TOMO',
  },
  {
    value: 88,
    label: 'VeChain',
    currency_short: 'VET',
  },
  {
    value: 89,
    label: 'Waves',
    currency_short: 'WAVES',
  },
  {
    value: 90,
    label: 'Wax',
    currency_short: 'WAX',
  },
  {
    value: 91,
    label: 'WEMIX',
    currency_short: 'WEMIX',
  },
  {
    value: 92,
    label: 'WaltonChain',
    currency_short: 'WTC_NATIVE',
  },
  {
    value: 93,
    label: 'Chia',
    currency_short: 'XCH',
  },
  {
    value: 94,
    label: 'Tezos',
    currency_short: 'XTZ',
  },
  {
    value: 95,
    label: 'Zcoin',
    currency_short: 'XZC',
  },
  {
    value: 96,
    label: 'Celestia',
    currency_short: 'TIA',
  },
  {
    value: 97,
    label: 'Neutron',
    currency_short: 'NTRN',
  },
  {
    value: 98,
    label: 'Avalanche-P',
    currency_short: 'AVAX-P',
  },
  {
    value: 99,
    label: 'Sei',
    currency_short: 'SEI',
  },
  {
    value: 100,
    label: 'Avalanche-X',
    currency_short: 'AVAX-X',
  },
  {
    value: 101,
    label: 'Aztec',
    currency_short: 'ASTRC',
  },
  {
    value: 102,
    label: 'Bittensor',
    currency_short: 'TAO',
  },
  {
    value: 103,
    label: 'Casper',
    currency_short: 'CSPR',
  },
  {
    value: 104,
    label: 'Deso',
    currency_short: 'DESO',
  },
  {
    value: 105,
    label: 'Juno',
    currency_short: 'JUNO',
  },
  {
    value: 106,
    label: 'Pocket Network',
    currency_short: 'POKT',
  },
  {
    value: 107,
    label: 'Ronin',
    currency_short: 'RON',
  },
  {
    value: 108,
    label: 'Vara',
    currency_short: 'VARA',
  },
  {
    value: 109,
    label: 'XPLA',
    currency_short: 'XPLA',
  },
  {
    value: 110,
    label: 'Archway',
    currency_short: 'ARCH',
  },
  {
    value: 111,
    label: 'Axelar',
    currency_short: 'AXL',
  },
  {
    value: 112,
    label: 'Dymension',
    currency_short: 'DYM',
  },
  {
    value: 113,
    label: 'Starknet',
    currency_short: 'STARKNET',
  },
  {
    value: 114,
    label: 'Orai',
    currency_short: 'ORAI',
  },
  {
    value: 115,
    label: 'Lukso',
    currency_short: 'LYX',
  },
  {
    value: 116,
    label: 'bitsCrunch',
    currency_short: 'BCUT',
  },
  {
    value: 117,
    label: 'Reef',
    currency_short: 'REEF',
  },
  {
    value: 118,
    label: 'Immutable zkEVM',
    currency_short: 'IMX',
  },
  {
    value: 119,
    label: 'Base Protocol',
    currency_short: 'BASE',
  },
  {
    value: 120,
    label: 'Arbitrum Nova',
    currency_short: 'ARBITRUM-NOVA',
  },
  {
    value: 121,
    label: 'Canto',
    currency_short: 'CANTO',
  },
  {
    value: 122,
    label: 'MetisDAO',
    currency_short: 'METIS',
  },
  {
    value: 123,
    label: 'Aptos',
    currency_short: 'APT',
  },
];

export const CaseStatus = [
  { value: 1, label: 'Opened', bgColor: 'bg-orange-500', textColor: 'text-white' },
  { value: 2, label: 'Closed', bgColor: 'bg-orange-200', textColor: 'text-gray-800' },
  { value: 3, label: 'Escalated', bgColor: 'bg-orange-700', textColor: 'text-white' },
  { value: 4, label: 'Pending Approval', bgColor: 'bg-orange-400', textColor: 'text-white' },
];

export const transactionTypes = [
  { label: 'Transactions', value: 'transaction', types: [1, 8] },
  { label: 'Internal Transactions', value: 'internal-transaction', types: [7] },
  { label: 'Token Transfers', value: 'token-transfer', types: [4] },
  { label: 'Rewards', value: 'rewards', types: [2, 3] },
];

export const transactionDirections = [
  { label: 'Incoming', value: '1' },
  { label: 'Outgoing', value: '2' },
];

export const TOPBAR_HEIGHT = '62px';
export const DASHBOARD_TABS_HEIGHT = '54.6px';
