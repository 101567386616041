import { isEmpty, reduce, toNumber } from 'lodash';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import DOMPurify from 'isomorphic-dompurify';
import { useRouter } from '../../modules/router/RouterProvider';
import { useAuth } from '../../modules/auth';
import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { IWorkspace } from '../../api/dtos/workspaces';
import { RiskLevel } from '../constants/constants';

export function hasAnyValue(input: unknown[]): boolean {
  return (
    reduce(
      input,
      (count, val) => {
        if (isEmpty(val)) {
          return count;
        }

        return count + 1;
      },
      0
    ) > 0
  );
}

export function mapToNumber(input: unknown): number | null {
  if (isEmpty(input)) {
    return null;
  }

  return toNumber(input);
}

export function hasNumber(myString: string): boolean {
  if (!myString) {
    return false;
  }
  return /\d/.test(myString);
}

// classnames with tw-merge
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cx(...args: any[]): string {
  return twMerge(classNames(...args));
}
// :TODO: once everything is migrated to react, we can use variables directly instead of css vars
export const calculateVh = (heightOffset = 0) =>
  `calc(100vh - var(--dashboard-tabs-height, 0px) - var(--screen-alert-height, 0px) - ${heightOffset}px)`;

export const sanitizeHtml = (html: string) => DOMPurify.sanitize(html);

interface IWorkspaces extends IWorkspace {
  workspaces: IWorkspace[];
}

export const useWorkspace = (): IWorkspaces => {
  const { navigate, pathname } = useRouter();
  const { state } = useAuth();
  const [workspaceLocal, setWorkspaceLocal] = useLocalStorage('workspace');
  const workspaces = state?.userProfile?.workspaces;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams);
  const workspaceSlug = queryParams.workspace;
  useEffect(() => {
    if (workspaces?.length) {
      const workspace =
        workspaces?.find((w) => w.slug === (workspaceSlug || workspaceLocal)) || workspaces?.[0];
      if (workspaceSlug) setWorkspaceLocal(workspace.slug);
      if (workspace.slug !== workspaceSlug) {
        navigate(pathname, { ...queryParams, workspace: workspace?.slug?.toString() });
        // Redirect to dashboard if current page is detail page and workspace is changed
        if (isDetailPage() && workspaceSlug)
          navigate('/dashboard', { workspace: workspace?.slug?.toString() });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, workspaceSlug, navigate, pathname, workspaceLocal, setWorkspaceLocal]);

  if (workspaces?.length) {
    return {
      ...(workspaces?.find((w) => w.slug === (workspaceSlug || workspaceLocal)) || workspaces?.[0]),
      workspaces,
    };
  }

  return {
    continuous_monitoring_level: 0,
    slug: workspaceSlug || '',
    name: '',
    workspaces: [],
    is_address_screening_type_any_enabled: false,
  };
};

export const useCurrentRole = (): { role: number; isAdmin: boolean; isOrgAdmin: boolean } => {
  const { state } = useAuth();
  const workspace = useWorkspace();
  return {
    role: state?.userProfile?.memberships?.[workspace?.slug]?.role,
    isAdmin:
      state?.userProfile?.is_organization_admin ||
      state?.userProfile?.memberships?.[workspace?.slug]?.role === 1 ||
      state?.userProfile?.role === 3,
    isOrgAdmin: state?.userProfile?.is_organization_admin || state?.userProfile?.role === 3,
  };
};

export const isDetailPage = () => {
  const pathArray = window.location.pathname.split('/');
  return (
    (['addresses', 'transactions', 'cases', 'customers'].includes(pathArray[1]) &&
      pathArray[2] &&
      !pathArray[3]) ||
    pathArray[1] === 'search'
  );
};

export const isDetailPageWithoutSearch = () => {
  const pathArray = window.location.pathname.split('/');
  return (
    ['addresses', 'transactions', 'cases', 'customers'].includes(pathArray[1]) &&
    pathArray[2] &&
    !pathArray[3]
  );
};

export const getUrlEnvironment = () => {
  let environment = window.location.hostname.split('.')[0];
  if (environment === 'compass') {
    environment = window.location.hostname.split('.')[1];
  }
  // If the environment is localhost, 127 or any dev, then we are in dev mode
  if (['localhost', '127', '0'].includes(environment) || /^dev/.test(environment)) {
    environment = 'dev';
  }
  return environment;
};

export const getRiskOptions = (levels = undefined) => {
  const riskOptions = (levels || [5, 4, 3, 2, 1, 0]).map((r) => {
    return {
      value: r?.toString(),
      label: RiskLevel.find((risk) => risk.value === r).label,
    };
  });
  return riskOptions;
};
