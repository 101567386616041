import { FC } from 'react';
import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';
import { formatDateTime } from '../../../../../utils/helpers/date';
import { Circle } from '@phosphor-icons/react';
import { useAuth } from '../../../../../modules/auth';
import { sanitizeHtml } from '../../../../../utils/helpers/common';

interface IAlertInfoItemProps {
  id: string | number;
  alertItem: Pick<IAlertItem, 'identifier' | 'body' | 'created_at' | 'updated_by' | 'updated_at'>;
  alert: IAlert;
  type: 'address' | 'transaction' | 'customer';
}

const AlertInfoItem: FC<IAlertInfoItemProps> = ({ alertItem, type }) => {
  const formatHtml = (str: string) => {
    const htmlClassNames =
      'whitespace-nowrap rounded-xl p-1 px-2 text-sm font-medium leading-7 text-gray-800 bg-gray-100';
    return str.replace(/<span>/g, `<span class="${htmlClassNames}">`);
  };
  const timezone = useAuth()?.state?.userProfile?.timezone;
  return (
    <div>
      <div className='flex justify-between p-2'>
        <div className='flex'>
          {type === 'customer' && (
            <div className='mt-2 px-2 text-2xs font-semibold'>{alertItem.identifier}</div>
          )}
          <div className='text-2xs text-gray-500'>
            {alertItem.body.map((item, i) => (
              <>
                <div key={i} dangerouslySetInnerHTML={{ __html: sanitizeHtml(formatHtml(item)) }}></div>
                {i !== alertItem.body.length - 1 && <div className='text-sm font-medium'>AND</div>}
              </>
            ))}
            <div className='mt-2 flex text-2xs text-gray-500'>
              {alertItem?.created_at && <p>Created at: {formatDateTime(alertItem?.created_at, timezone)}</p>}
              {alertItem?.created_at && alertItem?.updated_at && (
                <Circle size={5} weight='fill' className='mx-3 my-auto' />
              )}
              {alertItem?.updated_at && <p>Updated at: {formatDateTime(alertItem?.updated_at, timezone)}</p>}
              {(alertItem?.created_at || alertItem?.updated_at) && alertItem?.updated_by && (
                <Circle size={5} weight='fill' className='mx-3 my-auto' />
              )}
              {alertItem?.updated_by && <p>Last updated by: {alertItem?.updated_by}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertInfoItem;
