import { useQuery, UseQueryOptions } from 'react-query';
import { Api } from '../api';
import {
  IAutoAssignWorkflowResponse,
  IAutoAssignWorkflowRequest,
  IWorkflowStagesResponse,
} from '../dtos/settings/workflowSettings';

export class WorkflowSettingsApi extends Api {
  readonly baseUrl: string = '/profile/api/v2/';

  readonly getAutoAssignWorkflow = () => {
    return this.http.get<IAutoAssignWorkflowResponse>(this.route('workflow-stages/settings/'));
  };

  readonly updateAutoAssignWorkflow = (request: IAutoAssignWorkflowRequest) => {
    const { auto_assign_workflow_risk_levels, auto_assign_workflow_stage } = request;
    return this.http.patch(this.route(`workflow-stages/settings/`), {
      auto_assign_workflow_risk_levels: auto_assign_workflow_risk_levels,
      auto_assign_workflow_stage: auto_assign_workflow_stage,
    });
  };

  readonly getWorkflowStagesList = () => {
    return this.http.get<IWorkflowStagesResponse>(this.route('workflow/'));
  };
}

export const workflowSettings = new WorkflowSettingsApi();

export const useAutoAssignWorkflowGet = (options?: UseQueryOptions) => {
  const key = 'WorkflowSettingsApi.getAutoAssignWorkflow';
  const result = useQuery(key, () => workflowSettings.getAutoAssignWorkflow(), {
    ...options,
  } as unknown);

  return {
    key,
    ...result,
  };
};

export const useWorkflowStagesGet = (options?: UseQueryOptions) => {
  const key = ['WorkflowSettingsApi.getWorkflowStagesList'];
  const result = useQuery(key, () => workflowSettings.getWorkflowStagesList(), {
    ...options,
  } as unknown);

  return result;
};
