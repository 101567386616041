import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { ArrowRight, CaretDown, CaretRight, DotsThreeOutlineVertical } from '@phosphor-icons/react';
import { unescape } from 'lodash';

import { useRouter } from '../../../modules/router/RouterProvider';

import { IPolicyRuleAuditTrailsResponse } from '../../../api/dtos/policies';
import { policyApi, usePolicyRuleGetAuditTrail } from '../../../api/policies';
import { formatDateTime } from '../../../utils/helpers/date';
import { BasicBadge, BasicModal, Button, RiskBadge } from '../../../ui';

import Rule from '../../Risks/Rule';
import { toast } from 'react-toastify';
import Pagination from '../../../containers/Pagination';
import { useAuth } from '../../../modules/auth';

interface Props {
  policyType: string;
}

const RuleAuditTrail: React.FC<Props> = ({ policyType }) => {
  const { getParams } = useRouter();
  const params = getParams();
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();
  const auditPolicyRuleQuery = usePolicyRuleGetAuditTrail({
    id: params.policyId,
    ruleId: params.ruleId,
  });

  const [auditTrailsExpanded, setAuditTrailsExpanded] = React.useState<number[]>([]);

  const toggleIsExpanded = (index: number) => {
    if (auditTrailsExpanded.includes(index)) {
      setAuditTrailsExpanded(auditTrailsExpanded.filter((i) => i !== index));
    } else {
      setAuditTrailsExpanded([...auditTrailsExpanded, index]);
    }
  };

  const renderDiff = (item: IPolicyRuleAuditTrailsResponse, index: number) => {
    if (Object.keys(item.old_version).length === 0) {
      return (
        <div className='cursor-pointer border-b p-4' onClick={() => toggleIsExpanded(index)}>
          <div className='flex items-start gap-2'>
            <div className='pt-1 text-gray-500'>
              {auditTrailsExpanded.includes(index) ? (
                <CaretDown weight='bold' />
              ) : (
                <CaretRight weight='bold' />
              )}
            </div>
            <div>
              <div className='flex justify-between'>
                <p className='mb-1 text-sm font-medium text-gray-500'>
                  {item.new_version.revision.user !== null
                    ? `${item.new_version.revision.user.first_name} ${item.new_version.revision.user.last_name}`
                    : 'Merkle Science'}{' '}
                  created this rule
                </p>
                <RestoreDropdown
                  versionId={item.new_version.id}
                  date={item.new_version.revision.date_created}
                />
              </div>
              <p className='text-xs text-gray-500'>
                {formatDateTime(item?.new_version?.revision?.date_created, timezone)}
              </p>
            </div>
          </div>
          {auditTrailsExpanded.includes(index) && (
            <div>
              <p className='mb-1.5 pl-6 pt-4 text-xs'>
                <span className='font-medium'>Rule Name:</span>{' '}
                <span>{unescape(item.new_version.field_dict.rule_name)}</span>
              </p>
              <p className='pl-6 text-xs'>
                <span className='font-medium'>Rule Level:</span>{' '}
                <RiskBadge risk={item.new_version.field_dict.alert_level} />
              </p>
              <div className='pl-6 text-xs'>
                {item.new_version.field_dict.rule_conditions.map((r, i) => (
                  <div key={i} className='my-2 text-xs leading-6'>
                    <Rule
                      ruleType={r.rule_condition_type}
                      type={policyType as 'address' | 'transaction'}
                      {...r}
                      threshold_amount_usd={r.threshold_amount_usd}
                    />
                    {i !== item.new_version.field_dict.rule_conditions.length - 1 && (
                      <div className='text-gray-400'>AND</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    } else if (isRuleStatusChanged(item)) {
      return (
        <div className='cursor-pointer border-b p-4' onClick={() => toggleIsExpanded(index)}>
          <div className='flex items-start gap-2'>
            <div className='pt-1 text-gray-500'>
              {auditTrailsExpanded.includes(index) ? (
                <CaretDown weight='bold' />
              ) : (
                <CaretRight weight='bold' />
              )}
            </div>
            <div>
              <p className='mb-1 text-sm font-medium text-gray-500'>
                {item.new_version.revision.user !== null
                  ? `${item.new_version.revision.user.first_name} ${item.new_version.revision.user.last_name}`
                  : 'Merkle Science'}{' '}
                changed status to {item.new_version.field_dict.is_enabled === true ? 'live' : 'disabled'}
              </p>
              <p className='text-xs text-gray-500'>
                {formatDateTime(item?.new_version?.revision?.date_created, timezone)}
              </p>
            </div>
          </div>
          {auditTrailsExpanded.includes(index) && (
            <div>
              <p className='mb-1.5 flex items-center gap-2 pl-6 pt-4 text-xs'>
                <span className='font-medium'>Status: </span>
                <BasicBadge className='bg-gray-200'>
                  {item.old_version.field_dict.is_enabled === true ? 'live' : 'disabled'}
                </BasicBadge>
                <ArrowRight />
                <BasicBadge className='bg-gray-200'>
                  {item.new_version.field_dict.is_enabled === true ? 'live' : 'disabled'}
                </BasicBadge>
              </p>
            </div>
          )}
        </div>
      );
    } else if (ruleDetailsEdited(item)) {
      return (
        <div className='cursor-pointer border-b p-4' onClick={() => toggleIsExpanded(index)}>
          <div className='flex items-start gap-2'>
            <div className='pt-1 text-gray-500'>
              {auditTrailsExpanded.includes(index) ? (
                <CaretDown weight='bold' />
              ) : (
                <CaretRight weight='bold' />
              )}
            </div>
            <div>
              <div className='flex justify-between'>
                <p className='mb-1 text-sm font-medium text-gray-500'>
                  {item.new_version.revision.user !== null
                    ? `${item.new_version.revision.user.first_name} ${item.new_version.revision.user.last_name}`
                    : 'Merkle Science'}{' '}
                  edited this rule
                </p>
                <RestoreDropdown
                  versionId={item.new_version.id}
                  date={item.new_version.revision.date_created}
                />
              </div>
              <p className='text-xs text-gray-500'>
                {formatDateTime(item?.new_version?.revision?.date_created, timezone)}
              </p>
            </div>
          </div>
          {auditTrailsExpanded.includes(index) && (
            <div>
              {ruleDetailChanged(item).includes('name') && (
                <p className='mb-1.5 flex items-center gap-2 pl-6 pt-4 text-xs'>
                  <span className='font-medium'>Rule Name: </span>
                  <span className='line-through'>{unescape(item.old_version.field_dict.rule_name)}</span>
                  <ArrowRight />
                  <span>{unescape(item.new_version.field_dict.rule_name)}</span>
                </p>
              )}
              {ruleDetailChanged(item).includes('risk') && (
                <p className='mb-1.5 flex items-center gap-2 pl-6 pt-4 text-xs'>
                  <span className='font-medium'>Risk Level: </span>
                  <RiskBadge risk={item.old_version.field_dict.alert_level} />
                  <ArrowRight />
                  <RiskBadge risk={item.new_version.field_dict.alert_level} />
                </p>
              )}
            </div>
          )}
        </div>
      );
    } else if (ruleConditionAdded(item)) {
      return (
        <div className='cursor-pointer border-b p-4' onClick={() => toggleIsExpanded(index)}>
          <div className='flex items-start gap-2'>
            <div className='pt-1 text-gray-500'>
              {auditTrailsExpanded.includes(index) ? (
                <CaretDown weight='bold' />
              ) : (
                <CaretRight weight='bold' />
              )}
            </div>
            <div>
              <div className='flex justify-between'>
                <p className='mb-1 text-sm font-medium text-gray-500'>
                  {item.new_version.revision.user !== null
                    ? `${item.new_version.revision.user.first_name} ${item.new_version.revision.user.last_name}`
                    : 'Merkle Science'}{' '}
                  edited this rule
                </p>
                <RestoreDropdown
                  versionId={item.new_version.id}
                  date={item.new_version.revision.date_created}
                />
              </div>
              <p className='text-xs text-gray-500'>
                {formatDateTime(item?.new_version?.revision?.date_created, timezone)}
              </p>
            </div>
          </div>
          {auditTrailsExpanded.includes(index) && (
            <div>
              <p className='mb-1.5 gap-2 pl-6 pt-4 text-xs'>
                {item.new_version.field_dict.rule_conditions.map((r, i) => (
                  <div key={i} className='my-2 text-xs leading-6'>
                    <Rule
                      ruleType={r.rule_condition_type}
                      type={policyType as 'address' | 'transaction'}
                      {...r}
                      threshold_amount_usd={r.threshold_amount_usd}
                    />
                    {i !== item.new_version.field_dict.rule_conditions.length - 1 && (
                      <div className='text-gray-400'>AND</div>
                    )}
                  </div>
                ))}
                <p className='text-gray-400'>(Created Condition)</p>
              </p>
            </div>
          )}
        </div>
      );
    } else if (
      ruleConditionEdited.length > 0 &&
      Object.keys(item.old_version).length !== 0 &&
      isRuleEdited(item)
    ) {
      return (
        <div className='cursor-pointer border-b p-4' onClick={() => toggleIsExpanded(index)}>
          <div className='flex items-start gap-2'>
            <div className='pt-1 text-gray-500'>
              {auditTrailsExpanded.includes(index) ? (
                <CaretDown weight='bold' />
              ) : (
                <CaretRight weight='bold' />
              )}
            </div>
            <div>
              <div className='flex justify-between'>
                <p className='mb-1 text-sm font-medium text-gray-500'>
                  {item.new_version.revision.user !== null
                    ? `${item.new_version.revision.user.first_name} ${item.new_version.revision.user.last_name}`
                    : 'Merkle Science'}{' '}
                  edited this rule -{' '}
                  {Object.keys(item.new_version).length !== 0
                    ? unescape(item.new_version.field_dict.rule_name)
                    : ''}
                </p>
                <RestoreDropdown
                  versionId={item.new_version.id}
                  date={item.new_version.revision.date_created}
                />
              </div>
              <p className='text-xs text-gray-500'>
                {formatDateTime(item?.new_version?.revision?.date_created, timezone)}
              </p>
            </div>
          </div>
          {auditTrailsExpanded.includes(index) && (
            <div>
              <p className='mb-1.5 gap-2 pl-6 pt-4 text-xs'>
                {item.new_version.field_dict.rule_conditions.map((r, i) => (
                  <div key={i} className='my-2 text-xs leading-6'>
                    <Rule
                      ruleType={r.rule_condition_type}
                      type={policyType as 'address' | 'transaction'}
                      {...r}
                      threshold_amount_usd={r.threshold_amount_usd}
                    />
                    {i !== item.new_version.field_dict.rule_conditions.length - 1 && (
                      <div className='text-gray-400'>AND</div>
                    )}
                  </div>
                ))}
              </p>
            </div>
          )}
        </div>
      );
    }
  };

  if (auditPolicyRuleQuery.isLoading) {
    return (
      <div className='p-4'>
        <Skeleton count={3} height={40} />
      </div>
    );
  } else {
    return (
      <div className=''>
        <Pagination query={auditPolicyRuleQuery} className='mb-auto flex flex-col' loader='compass'>
          {(data, i) => renderDiff(data, i)}
        </Pagination>
      </div>
    );
  }
};

export default RuleAuditTrail;

const isRuleStatusChanged = (item) => {
  if (
    item.old_version !== null &&
    Object.keys(item.old_version).length !== 0 &&
    item.new_version.field_dict.is_enabled !== item.old_version.field_dict.is_enabled
  ) {
    return true;
  } else {
    return false;
  }
};

const ruleDetailsEdited = (item) => {
  if (
    Object.keys(item.old_version).length !== 0 &&
    (item.new_version.field_dict.rule_name !== item.old_version.field_dict.rule_name ||
      item.new_version.field_dict.alert_level !== item.old_version.field_dict.alert_level)
  ) {
    return true;
  } else {
    return false;
  }
};

const ruleConditionAdded = (item) => {
  if (
    Object.keys(item.old_version).length !== 0 &&
    item.new_version.field_dict.rule_conditions.length > item.old_version.field_dict.rule_conditions.length
  ) {
    return true;
  } else {
    return false;
  }
};
const isRuleEdited = (item) => {
  if (
    item.new_version.field_dict.rule_conditions.length !== item.old_version.field_dict.rule_conditions.length
  ) {
    return false;
  } else {
    return true;
  }
};

const ruleConditionEdited = (auditTrailData) => {
  const arr = [];
  auditTrailData.forEach((item) => {
    if (
      Object.keys(item.old_version).length !== 0 &&
      item.new_version.field_dict.rule_conditions.length ===
        item.old_version.field_dict.rule_conditions.length
    ) {
      const temp = [];

      const obj = {
        entity: temp,
        risk: temp,
        taint: temp,
        amount: temp,
        upper_amount: temp,
        analysis_window: temp,
        transit_window: temp,
        payment_amount_percent: temp,
        dormancy_window: temp,
        transaction_amount_threshold: temp,
        address_age: temp,
        lower_bound: temp,
        upper_bound: temp,
        transaction_count_threshold: temp,
        transaction_count_upper_threshold: temp,
        multiplier_threshold: temp,
        inactivity_types: temp,
        counterparty_threshold: temp,
        sentiments: temp,
      };

      item.new_version.field_dict.rule_conditions.forEach((val, index) => {
        const oldItem = item.old_version.field_dict.rule_conditions[index];
        if (val.entity_types && val.entity_types.length !== oldItem.entity_types.length) {
          obj.entity.push(index);
        } else if (val.entity_types && val.entity_types.length === oldItem.entity_types.length) {
          val.entity_types.forEach((entity) => {
            if (!oldItem.entity_types.includes(entity)) {
              obj.entity.push(index);
            }
          });
        }
        if (val.entity_subtypes && val.entity_subtypes.length !== oldItem.entity_subtypes.length) {
          obj.entity.push(index);
        } else if (val.entity_subtypes && val.entity_subtypes.length === oldItem.entity_subtypes.length) {
          val.entity_subtypes.forEach((entity) => {
            if (!oldItem.entity_subtypes.includes(entity)) {
              obj.entity.push(index);
            }
          });
        }
        if (val.entity_names && val.entity_names.length !== oldItem.entity_names.length) {
          obj.entity.push(index);
        } else if (val.entity_names && val.entity_names.length === oldItem.entity_names.length) {
          val.entity_names.forEach((entity) => {
            if (!oldItem.entity_names.includes(entity)) {
              obj.entity.push(index);
            }
          });
        }
        if (val.risk_types.length !== oldItem.risk_types.length) {
          obj.risk.push(index);
        } else {
          val.risk_types.forEach((risk) => {
            if (!oldItem.risk_types.includes(risk)) {
              obj.risk.push(index);
            }
          });
        }
        if (val.taint_percent_threshold !== oldItem.taint_percent_threshold) {
          obj.taint.push(index);
        }
        if (val.taint_percent_upper_threshold !== oldItem.taint_percent_upper_threshold) {
          obj.taint.push(index);
        }
        if (val.threshold_amount_usd !== oldItem.threshold_amount_usd) {
          obj.amount.push(index);
        }
        if (val.upper_threshold_amount_usd !== oldItem.upper_threshold_amount_usd) {
          obj.upper_amount.push(index);
        }
        if (val.analysis_window !== oldItem.analysis_window) {
          obj.analysis_window.push(index);
        }
        if (val.transit_window !== oldItem.transit_window) {
          obj.transit_window.push(index);
        }
        if (val.payment_amount_percent !== oldItem.payment_amount_percent) {
          obj.payment_amount_percent.push(index);
        }
        if (val.dormancy_window !== oldItem.dormancy_window) {
          obj.dormancy_window.push(index);
        }
        if (val.transaction_amount_threshold !== oldItem.transaction_amount_threshold) {
          obj.transaction_amount_threshold.push(index);
        }
        if (val.address_age !== oldItem.address_age) {
          obj.address_age.push(index);
        }
        if (val.lower_bound !== oldItem.lower_bound) {
          obj.lower_bound.push(index);
        }
        if (val.upper_bound !== oldItem.upper_bound) {
          obj.upper_bound.push(index);
        }
        if (val.transaction_count_threshold !== oldItem.transaction_count_threshold) {
          obj.transaction_count_threshold.push(index);
        }
        if (val.transaction_count_upper_threshold !== oldItem.transaction_count_upper_threshold) {
          obj.transaction_count_upper_threshold.push(index);
        }
        if (val.multiplier_threshold !== oldItem.multiplier_threshold) {
          obj.multiplier_threshold.push(index);
        }
        if (val.inactivity_types && val.inactivity_types.length !== oldItem.inactivity_types.length) {
          obj.inactivity_types.push(index);
        } else if (val.inactivity_types && val.inactivity_types.length === oldItem.inactivity_types.length) {
          val.inactivity_types.forEach((risk) => {
            if (!oldItem.inactivity_types.includes(risk)) {
              obj.inactivity_types.push(index);
            }
          });
        }
        if (val.counterparty_threshold !== oldItem.counterparty_threshold) {
          obj.counterparty_threshold.push(index);
        }
        if (val.sentiments && val.sentiments.length !== oldItem.sentiments.length) {
          obj.sentiments.push(index);
        } else if (val.sentiments && val.sentiments.length === oldItem.sentiments.length) {
          val.sentiments.forEach((sentiment) => {
            if (!oldItem.sentiments.includes(sentiment)) {
              obj.sentiments.push(index);
            }
          });
        }
      });

      arr.push(obj);
    } else {
      const obj = {};
      arr.push(obj);
    }
  });
  return arr;
};

const ruleDetailChanged = (item) => {
  const ruleDetail = [];
  if (item.new_version.field_dict.rule_name !== item.old_version.field_dict.rule_name) {
    ruleDetail.push('name');
  }
  if (item.new_version.field_dict.alert_level !== item.old_version.field_dict.alert_level) {
    ruleDetail.push('risk');
  }
  return ruleDetail;
};

// const isRuleConditionEdited = (pos, index, auditTrailData) => {
//   if (
//     !ruleConditionEdited(auditTrailData)[pos].entity.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].risk.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].taint.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].amount.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].upper_amount.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].analysis_window.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].transit_window.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].payment_amount_percent.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].dormancy_window.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].transaction_amount_threshold.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].address_age.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].lower_bound.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].upper_bound.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].transaction_count_threshold.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].multiplier_threshold.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].inactivity_types.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].counterparty_threshold.includes(index) &&
//     !ruleConditionEdited(auditTrailData)[pos].sentiments.includes(index)
//   ) {
//     return false;
//   } else {
//     return true;
//   }
// };

const RestoreDropdown: React.FC<{ versionId: number; date: string }> = ({ versionId, date }) => {
  const controlsRef = React.useRef(null);

  const { navigate, getParams } = useRouter();
  const params = getParams();
  const policyId = params.policyId;
  const ruleId = params.ruleId;
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    const handleLegendsClickOutside = (event) => {
      if (controlsRef.current && !controlsRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('click', handleLegendsClickOutside, true);
    return () => {
      document.removeEventListener('click', handleLegendsClickOutside, true);
    };
  }, []);

  const restoreVersion = async () => {
    const res = await policyApi.revertPolicyRule(String(policyId), String(ruleId), versionId);

    if (res.status === 200) {
      toast.success('Rule edited successfully');
      navigate(`/settings/workspace/policies/${policyId}`);
    }
  };

  return (
    <>
      <div className='relative ml-1'>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsDropdownOpen(true);
          }}
          className='cursor-pointer rounded p-1 hover:bg-gray-50'>
          <DotsThreeOutlineVertical size={17} />
        </button>
        {isDropdownOpen && (
          <div className='absolute left-1 top-1 min-w-[200px] text-sm shadow-md' ref={controlsRef}>
            <button
              className='w-full cursor-pointer bg-white p-4 text-left font-medium hover:bg-gray-100'
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(true);
                setIsDropdownOpen(false);
              }}>
              Restore this version
            </button>
          </div>
        )}
      </div>
      <BasicModal
        size='sm'
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalTitle='Restore Version'
        modalBody={
          <div className='px-2 pt-4 text-sm'>
            <p className='mb-5'>
              Restoring to an earlier version will not make any changes in the alerts created since.
            </p>
            <p>Are you sure you want to restore rule to the version dated {formatDateTime(date, timezone)}</p>
            <div className='mt-3 flex justify-end gap-2'>
              <Button
                type='button'
                variant='tertiary'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen(false);
                }}>
                Cancel
              </Button>
              <Button
                type='button'
                variant='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  restoreVersion();
                }}>
                Restore
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
};
