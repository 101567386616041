/* eslint-disable no-console */
import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { useRouter } from '../../modules/router/RouterProvider';
import { useTheme } from '../../utils/helpers/theme';

interface Props {}

const SearchComponent: React.FC<Props> = () => {
  const { getQueryParams, pathname } = useRouter();
  const { themeColor } = useTheme();
  const { q, type } = getQueryParams();
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchTerm, setSearchTerm] = useState(q ? q : '');
  const menuListRef = useRef(null);
  const { navigate } = useRouter();
  const searchLimit = 5;

  const onClickEnter = (event) => {
    if (searchTerm.length < searchLimit) return;
    if (event.key === 'Enter') {
      setSearchFocus(false);
      document.getElementById('searchBar').blur();
      navigate('/search', {
        q: searchTerm,
      });
    } else return;
  };

  const onRemoveQuery = () => {
    setSearchTerm('');
    if (['addresses', 'transactions', 'customers', 'cases'].includes(type)) {
      navigate(`/${type}`);
    } else {
      navigate('/dashboard');
    }
  };

  const controlRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuListRef.current &&
        !menuListRef.current.contains(event.target) &&
        !controlRef.current.contains(event.target)
      ) {
        setSearchFocus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuListRef]);

  useEffect(() => {
    if (q && type) setSearchTerm(q);
  }, [q, type]);

  useEffect(() => {
    if (!pathname.startsWith('/search')) {
      setSearchTerm('');
      setSearchFocus(false);
    }
  }, [pathname]);

  return (
    <div ref={controlRef}>
      <div className='z-998 relative flex items-center justify-center'>
        <div
          style={{ backgroundColor: searchFocus ? themeColor().focused : themeColor().secondary }}
          className={`my-2 flex h-[38px] w-[561px] items-center rounded-md p-[9px]`}>
          <MagnifyingGlass size={20} className={`${searchFocus ? 'text-gray-500' : 'text-white'}`} />
          <input
            value={searchTerm}
            type='text'
            id='searchBar'
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`size-full border-none bg-transparent text-sm font-normal outline-none focus:border-transparent focus:ring-transparent ${
              searchFocus ? 'text-gray-800 placeholder:text-gray-800' : 'text-white placeholder:text-white'
            } `}
            placeholder='Search for Address, Transaction, Customer or Case'
            onFocus={() => setSearchFocus(true)}
            onKeyDown={(e) => onClickEnter(e)}
            autoComplete='off'
            autoCorrect='off'
          />
          {searchTerm && (
            <X
              size={22}
              className={`${searchFocus ? 'text-blue-500' : 'text-white'} cursor-pointer`}
              onClick={onRemoveQuery}
              weight='bold'
            />
          )}
        </div>
      </div>
      {searchFocus && (
        <div
          ref={menuListRef}
          className={
            searchFocus
              ? 'top-15 left-30 absolute max-h-[90vh] w-[561px] overflow-y-auto rounded-lg border border-solid border-gray-200 bg-white p-2 text-xs shadow-2xl'
              : 'text-xs'
          }>
          <div className='p-2 text-2xs font-medium text-gray-800'>
            {searchTerm.trim().length < searchLimit
              ? `Please enter at least ${searchLimit} characters`
              : 'Press enter to search'}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
