import * as Sentry from '@sentry/react';
import React from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

Sentry.init({
  enabled: !!import.meta.env.VITE_SENTRY_ENABLED || false,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.extraErrorDataIntegration(),
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
});
