import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { Dropdown, DropdownOption } from '../../ui';
import { differenceInCalendarDays, format } from 'date-fns';
import CustomDayPicker from '../ui/components/CustomDayPicker';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { CalendarBlank } from '@phosphor-icons/react';
import { dateToString } from '../../utils/helpers/date';

interface CalendarFilterProps {
  value: IDateRangeString;
  setValue: (date: IDateRangeString) => void;
  label?: string;
  placeholder?: string;
  isParcel?: boolean;
  isOutsideFilter?: boolean;
  extraDays?: number[];
  onDropdownChange?: (option: DropdownOption) => void;
}

export interface IDateRangeString {
  from: string;
  to: string;
}

type DateRange = {
  from: Date;
  to: Date;
};

const CalendarFilter: FC<CalendarFilterProps> = (props) => {
  const {
    label,
    placeholder,
    value: propValue,
    setValue: propSetValue,
    isParcel = false,
    isOutsideFilter = false,
    onDropdownChange,
  } = props;
  const days = useMemo(() => [7, 30, 60, 90, 180, 365], []);

  const [value, setValue] = useState<DateRange>();

  useEffect(() => {
    if (propValue?.from && propValue?.to) {
      setValue({ from: new Date(propValue.from), to: new Date(propValue.to) });
    } else {
      setValue(null);
    }
  }, [propValue]);

  const getSelectText = useCallback(() => {
    if (!value || (!value.from && !value.to)) {
      return placeholder || 'Select Date Range';
    }

    const from = value.from ? format(value.from, 'MMM dd, yyyy') : `Select From date`;
    const to = value.to ? format(value.to, 'MMM dd, yyyy') : `Select To date`;
    return `${from} - ${to}`;
  }, [placeholder, value]);

  const [dropdownValue, setDropdownValue] = useState<DropdownOption>();

  useEffect(() => {
    onDropdownChange?.(dropdownValue);
  }, [dropdownValue, onDropdownChange]);

  const options = useMemo<DropdownOption[]>(() => {
    const daysOptions = days.map((day) => ({
      label: `Last ${day} Days`,
      value: day,
    }));
    const monthOptions = [
      {
        label: 'Last Month',
        value: 'last_month',
      },
      {
        label: 'This Month',
        value: 'this_month',
      },
    ];
    const yearOptions = [
      {
        label: 'Last Year',
        value: 'last_year',
      },
      {
        label: 'This Year',
        value: 'this_year',
      },
    ];
    return [
      ...daysOptions,
      ...monthOptions,
      ...yearOptions,
      {
        label: 'Custom',
        value: 'custom',
      },
    ];
  }, [days]);

  useEffect(() => {
    if (!value?.from || !value?.to) {
      setDropdownValue(null);
    } else {
      const today = new Date();
      const diff = differenceInCalendarDays(value.to, value.from);
      let option = null;
      if (
        dateToString(value.from) === dateToString(new Date(today.getFullYear(), today.getMonth(), 1)) &&
        dateToString(value.to) === dateToString(today)
      ) {
        option = options.find((option) => option.value === 'this_month');
      } else if (
        dateToString(value.from) === dateToString(new Date(today.getFullYear(), 0, 1)) &&
        dateToString(value.to) === dateToString(today)
      ) {
        option = options.find((option) => option.value === 'this_year');
      } else if (
        dateToString(value.from) === dateToString(new Date(today.getFullYear(), today.getMonth() - 1, 1)) &&
        dateToString(value.to) === dateToString(new Date(today.getFullYear(), today.getMonth(), 0))
      ) {
        option = options.find((option) => option.value === 'last_month');
      } else if (
        dateToString(value.from) === dateToString(new Date(today.getFullYear() - 1, 0, 1)) &&
        dateToString(value.to) === dateToString(new Date(today.getFullYear() - 1, 11, 31))
      ) {
        option = options.find((option) => option.value === 'last_year');
      } else {
        option = options.find((option) => option.value === diff + 1);
      }
      if (option) {
        setDropdownValue(option);
      } else {
        setDropdownValue({
          label: getSelectText(),
          value: 'custom',
        });
      }
    }
  }, [getSelectText, options, value, days]);

  const onClickExistingDay = (day: number) => {
    const from = new Date();
    from.setDate(from.getDate() - day + 1);
    setValue({ from, to: new Date() });
    propSetValue({ from: dateToString(from), to: dateToString(new Date()) });
  };

  const handleChange = (selected: DateRange) => {
    setValue(selected);
    if (selected.from && selected.to) {
      propSetValue({ from: dateToString(selected.from), to: dateToString(selected.to) });
    } else if (!selected.from && !selected.to) {
      propSetValue({ from: null, to: null });
    }
  };

  const handleDropdownChange = (option: DropdownOption) => {
    setDropdownValue(option);
    const today = new Date();
    let from = null as Date;
    let to = null as Date;
    switch (option.value) {
      case 'custom':
        setPopoverVisible(true);
        break;
      case 'last_month':
        from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        to = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'this_month':
        from = new Date(today.getFullYear(), today.getMonth(), 1);
        to = today;
        break;
      case 'last_year':
        from = new Date(today.getFullYear() - 1, 0, 1);
        to = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 'this_year':
        from = new Date(today.getFullYear(), 0, 1);
        to = today;
        break;
      default:
        onClickExistingDay(Number(option.value));
        break;
    }
    if (from && to) {
      setValue({ from, to });
      propSetValue({ from: dateToString(from), to: dateToString(to) });
    }
  };

  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    isParcel
      ? { placement: 'bottom' }
      : {
          placement: 'bottom-end',
        }
  );

  useEffect(() => {
    if (!popoverVisible && dropdownValue?.value === 'custom') {
      setDropdownValue({ label: getSelectText(), value: 'custom' });
    }
  }, [dropdownValue?.value, getSelectText, popoverVisible, value]);

  return (
    <div className='relative'>
      {label && <label className='mb-1 block text-sm font-medium text-gray-500'>{label}</label>}

      <div ref={setReferenceElement}>
        <Dropdown
          customClass='text-gray-500 text-sm'
          value={dropdownValue}
          placeholder={placeholder || 'Select Date Range'}
          onChange={handleDropdownChange}
          options={options}
          customCaretClass={classNames({ hidden: isOutsideFilter })}
          optionsCustomClass='calendar-option'
          icon={<CalendarBlank size={16} className='my-auto mr-2 text-gray-400' />}
        />
      </div>
      <div
        ref={setPopperElement}
        style={styles.popper}
        className={classNames('z-50 flex rounded border bg-white shadow-lg', {
          '-left-20': isParcel && !isOutsideFilter,
          '-left-[500px]': isParcel && isOutsideFilter,
        })}
        {...attributes.popper}>
        <CustomDayPicker
          value={value}
          handleChange={handleChange}
          visible={popoverVisible}
          setVisible={setPopoverVisible}
        />
      </div>
    </div>
  );
};

export default CalendarFilter;
