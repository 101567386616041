import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useAuth } from '../../modules/auth';
import { BasicModal, Button, ConfirmationModal, RadioButton } from '../../ui';
import { CaretDown, GearSix, UserGear } from '@phosphor-icons/react';
import CustomLink from '../ui/components/CustomLink';
import { isDetailPage, useCurrentRole, useWorkspace } from '../../utils/helpers/common';
import { useRouter } from '../../modules/router/RouterProvider';
import { IWorkspace } from '../../api/dtos/workspaces';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useTheme } from '../../utils/helpers/theme';
import styles from '../../styles/scroll.module.scss';

interface HeaderMenuProps {
  onLogout?: () => void;
}

const HeaderMenu: FC<HeaderMenuProps> = ({ onLogout }) => {
  const workspace = useWorkspace();
  const { state } = useAuth();
  const { navigate } = useRouter();

  const [logOutModal, setLogOutModal] = useState(false);
  const [workspaceConfirmation, setWorkspaceConfirmation] = useState<IWorkspace>();
  const [isSettings, setIsSettings] = useState(false);
  const [, setTabStorage] = useLocalStorage('isTabVisible');

  const nameInitials =
    (state.userProfile?.first_name?.charAt(0) || '') + (state.userProfile?.last_name?.charAt(0) || '');

  const handleLogout = () => {
    setLogOutModal(false);
    setTabStorage(true);
    onLogout?.();
  };
  const queryClient = useQueryClient();

  const onChangeWorkspace = (w: IWorkspace) => {
    setWorkspaceConfirmation(undefined);
    if (!isSettings) {
      // check if path is /addresses/id or /transactions/id or /cases/id or /customers/id
      // if yes, redirect to /dashboard
      if (isDetailPage()) {
        navigate('/dashboard', { workspace: w.slug });
      } else {
        navigate('', { workspace: w.slug });
      }
    } else {
      navigate('/settings/workspace/members', { workspace: w.slug });
    }
    setTimeout(() => {
      queryClient.invalidateQueries();
      queryClient.removeQueries();
    }, 100);
    toast.success(`Workspace switched successfully to ${w.name}`);
  };

  const handleWorkspaceChange = (w: IWorkspace, isSettings = false) => {
    if (w.slug === workspace.slug) {
      if (isSettings) navigate('/settings/workspace/members', { workspace: w.slug });
      return;
    }
    setIsSettings(isSettings);
    setWorkspaceConfirmation(w);
  };

  const { isAdmin, isOrgAdmin } = useCurrentRole();
  const { themeColor } = useTheme();

  return (
    <div>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <MenuButton
            className={classNames(
              'my-px rounded-lg px-3 py-2 text-sm font-medium text-white outline-1 hover:outline hover:outline-white focus:outline-none'
            )}
            style={{ backgroundColor: themeColor().secondary }}>
            <div className='flex'>
              <div className='whitespace-nowrap'>{workspace.name}</div>
              <div
                className='mx-2 rounded-full border border-white p-1 text-[8px]'
                style={{ backgroundColor: themeColor().primary }}>
                {nameInitials}
              </div>
              <CaretDown size={16} weight='fill' className='my-auto text-white' />
            </div>
          </MenuButton>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <MenuItems className='absolute right-0 mt-2 min-w-[335px] rounded-md border bg-white shadow-lg focus:outline-none'>
            <MenuItem>
              {({ close }) => (
                <div className='py-2 pl-4 pr-1' onClick={(e) => e.preventDefault()}>
                  <div className='flex justify-between'>
                    <div className='mr-4'>
                      <div className='text-xs text-gray-700'>Signed in as</div>
                      <div className='text-sm text-gray-800'>{state.userProfile?.email}</div>
                    </div>
                    <div onClick={close}>
                      <CustomLink to='/settings/user/profile'>
                        <Button variant='tertiary' className='px-3 py-2'>
                          <UserGear size={20} />
                        </Button>
                      </CustomLink>
                    </div>
                  </div>
                </div>
              )}
            </MenuItem>
            <MenuItem>
              {({ close }) => (
                <div className='bg-gray-100 p-1' onClick={(e) => e.preventDefault()}>
                  <div>
                    <div className='px-4 py-2 text-sm uppercase text-gray-700'>
                      Workspaces ({state?.userProfile?.workspaces?.length})
                    </div>
                    <div className={classNames('max-h-72 overflow-auto', styles.forceScroll)}>
                      {state?.userProfile?.workspaces?.map((w) => (
                        <div className='mb-1 flex justify-between' key={w.slug} onClick={close}>
                          <div
                            onClick={() => handleWorkspaceChange(w)}
                            className={classNames(
                              'my-auto flex w-full cursor-pointer rounded-md border px-2 py-1.5 text-xs hover:border-blue-500',
                              w.slug === workspace.slug
                                ? 'border-blue-200 bg-blue-50'
                                : 'border-gray-200 bg-white'
                            )}>
                            <RadioButton
                              value={w.slug}
                              labelClassName='mb-0'
                              inputClassName='my-auto'
                              selected={w.slug === workspace.slug}
                            />
                            {w.name}
                          </div>
                          {isAdmin && (
                            <Button
                              onClick={() => handleWorkspaceChange(w, true)}
                              variant='tertiary'
                              className='mx-1 h-8 w-14 bg-white px-3 py-2 text-gray-700'>
                              <GearSix className='mx-auto' size={16} />
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </MenuItem>
            {isOrgAdmin && (
              <MenuItem>
                {({ close }) => (
                  <div
                    onClick={(e) => e.preventDefault()}
                    className='cursor-pointer rounded-md text-gray-800 hover:bg-blue-500'>
                    <div onClick={close}>
                      <CustomLink
                        to='/settings/organisation/members'
                        className='block px-4 py-2 text-sm hover:text-white'>
                        Organisation Settings
                      </CustomLink>
                    </div>
                  </div>
                )}
              </MenuItem>
            )}
            <MenuItem
              as={'div'}
              onClick={() => setLogOutModal(true)}
              className='cursor-pointer rounded-md px-4 py-2 text-sm text-red-600 hover:bg-blue-500 hover:text-white'>
              <div className='rounded-md hover:text-white'>Logout</div>
            </MenuItem>
          </MenuItems>
        </Transition>
      </Menu>
      <ConfirmationModal
        onConfirm={(w: IWorkspace) => {
          onChangeWorkspace(w);
        }}
        open={!!workspaceConfirmation}
        data={workspaceConfirmation}
        onCancel={() => setWorkspaceConfirmation(undefined)}
        title={'Switch workspace'}
        body={'Are you sure you want to switch workspace?'}
      />
      <BasicModal
        open={logOutModal}
        onClose={() => setLogOutModal(false)}
        modalTitle='Log out'
        customBodyClass='text-sm p-5'
        modalBody='Are you sure you want to logout?'
        size='sm'
        modalFooter={
          <div className='flex justify-end'>
            <Button variant='tertiary' className='mr-3' onClick={() => setLogOutModal(false)}>
              Cancel
            </Button>
            <Button variant='danger' onClick={handleLogout}>
              Yes, Log out!
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default HeaderMenu;
